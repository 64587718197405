import React, { useState } from "react";
import {
    Chip,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { PRIVACY_NAMES } from "@icarius-pages/feed/constants";
import PrivacyDialog from "./privacyDialog";

const PrivacyInput = (props) => {

    const {
        data,
        groups,
        tasks,
        people,
        isFeedback,
        statusToCreate,
        handleChange,
    } = props;

    const [privacyDialogIsOpen, setPrivacyDialogIsOpen] = useState(false);
    const color = useSelector(getAppColor);

    const handleClick = () => {
        setPrivacyDialogIsOpen(true);
    }

    return (
        <>
            <Chip
                label={PRIVACY_NAMES[data.type]}
                onClick={handleClick}
                onDelete={handleClick}
                deleteIcon={<KeyboardArrowDownIcon style={{ color: 'var(--svgColor)' }} />}
                style={{
                    background: color,
                    cursor: 'pointer',
                    color: 'white',
                }}
            />
            {
                privacyDialogIsOpen &&
                <PrivacyDialog
                    open={privacyDialogIsOpen}
                    data={data}
                    groups={groups}
                    tasks={tasks}
                    people={people}
                    handleChange={handleChange}
                    isFeedback={isFeedback}
                    statusToCreate={statusToCreate}
                    handleClose={() => setPrivacyDialogIsOpen(false)}
                />
            }
        </>
    )
}

export default PrivacyInput;