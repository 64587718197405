import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "@icarius-common/textEditor";
import { MenuItem } from "@material-ui/core";
import { getUserData } from "src/app/selectors";
import useForceRender from "@icarius-utils/hooks/useForceRender";
import { openDialogAction } from "@icarius-common/dialog/actions";
import Header from "./components/header";
import ParticipationIndicator from "./components/participationIndicator";
import CommentsAndLikesIndicator from "./components/commentsAndLikesIndicator";
import EventIndicator from "./components/eventIndicator";
import DisabledIndicator from "./components/disabledIndicator";
import CommentList from "../comment/list";
import DisableDialog from "./components/disableDialog";
import TypeIndicator from "./components/typeIndicator";
import ReferenceIndicator from "./components/referenceIndicator";
import PollIndicator from "./components/pollIndicator";
import FormDialog from "../form/formDialog";
import Image from "../form/components/image";
import LinkDownloadItem from "../form/components/linkDownloadPollItem";

const Publication = (props) => {

    const {
        data,
        polls,
        goals,
        groups,
        tasks,
        modeList,
        typeList,
        people,
        comments,
        isLoading,
        actions,
        isFeedback,
        forceReadOnly,
    } = props;

    const key = useForceRender(data.text);

    const [formDialogIsOpen, setFormDialogIsOpen] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [disableDialogIsOpen, setDisableDialogIsOpen] = useState(false);

    const dispatch = useDispatch();
    const userData = useSelector(getUserData);

    const publicationComments = comments.find((item) => item.publicationCode === data.publicationCode)?.comments || [];

    const isOwner = userData.code === data.owner.code;
    const canDisable = userData.level === 'E' && !isOwner && data.status?.enabled === true;
    const canReactivate = userData.level === 'E' && !isOwner && data.status?.enabled === false;
    const readOnly = forceReadOnly || (userData.code !== data.owner.code && data.privacy.type !== 'U' && !data.privacy.userCodes.includes(userData.code));
    const canDownloadParticipationData = isOwner || userData.level === 'E';

    const handleDelete = () => {
        const message = (() => {
            if (!data.associatedWithTask) return `¿Desea borrar ${isFeedback ? 'el feedback' : 'la publicación'}?`;
            return (
                <>
                    {`Este feedback está vinculado a la tarea: ${tasks.find((item) => item.key === data.task)?.name}`}
                    <br />
                    <br />
                    Si elimina el feedback, también se eliminará la tarea completa y todo lo que esta contenga (Actividades, avances, adjuntos y comentarios)
                </>
            )
        })();

        dispatch(openDialogAction({
            msg: message,
            title: 'Atención',
            onConfirm: () => dispatch(actions.deletePublication({ publicationCode: data.publicationCode })),
        }));
    }

    const handleChangeStatus = (reason) => {
        dispatch(actions.changePublicationStatus({
            reason,
            publicationCode: data.publicationCode,
            enabled: !data.status.enabled,
        }))
    }

    const handleToggleComments = () => {
        if (!showComments && !publicationComments?.length) {
            dispatch(actions.getComments(data.publicationCode));
        }

        setShowComments(prev => !prev);
    }

    const handleIndicateParticipation = (status) => {
        if (readOnly) return;
        if (isLoading?.likesParticipation) return;
        if (status === data.participation.includes(userData.code)) return; // si toque el mismo estado que ya tiene, no hago nada

        dispatch(actions.indicatePublicationParticipation({
            status,
            userCode: userData.code,
            publicationCode: data.publicationCode,
        }))
    }

    const handleDownloadParticipation = () => {
        dispatch(actions.downloadPublicationParticipation(data.participation, `Asistentes_Confirmados_Evento_${data.date.replaceAll('/', '')}`));
    }

    const handleLike = () => {
        if (isLoading?.likesParticipation) return;
        dispatch(actions.likePublication({
            status: !data.likes.includes(userData.code),
            userCode: userData.code,
            publicationCode: data.publicationCode,
        }))
    }

    const handleOpenFormDialog = () => {
        setFormDialogIsOpen(true);
        if (isFeedback && data.privacy.type === 'P') {
            dispatch(actions.getGoals(data.privacy.userCodes[0], data.status));
        }
    }

    return (
        <>
            <div>
                {
                    !isFeedback &&
                    <DisabledIndicator
                        data={data.status}
                    />
                }
                <Header
                    image={data.owner.image}
                    name={data.owner.name}
                    role={data.owner.role}
                    date={data.modifiedDate}
                    time={data.modifiedTime}
                    edited={data.edited}
                    privacy={data.privacy}
                    statusText={data.statusText}
                    modeText={modeList?.find((item) => item.key === data.mode)?.value || ''}
                    people={people}
                    hasMenu={(isOwner || canDisable || canReactivate) && !readOnly}
                    menuOptions={
                        [
                            canDisable && !isFeedback &&
                            <MenuItem key={1} onClick={() => setDisableDialogIsOpen(true)}>
                                Desactivar
                            </MenuItem>,
                            canReactivate && !isFeedback &&
                            <MenuItem key={2} onClick={() => handleChangeStatus('')}>
                                Reactivar
                            </MenuItem>,
                            isOwner &&
                            <MenuItem key={3} onClick={handleOpenFormDialog}>
                                Editar
                            </MenuItem>,
                            isOwner &&
                            <MenuItem key={4} onClick={handleDelete}>
                                Borrar
                            </MenuItem>,
                        ]
                    }
                />
                <EventIndicator
                    date={data.date}
                    label={isFeedback ? 'Revisión' : 'Evento'}
                />
                <PollIndicator
                    readOnly={readOnly}
                    data={data.poll}
                />
                <ReferenceIndicator
                    data={data.reference}
                />
                <div style={{ padding: '10px 0px' }}>
                    <TextEditor
                        key={key}
                        readOnly
                        initialValue={data.text}
                        fontSize={20}
                        lineHeight={'34px'}
                    />
                </div>
                <TypeIndicator
                    data={data.type}
                    typeList={typeList}
                />
            </div>
            <Image
                data={data.image}
                readOnly
            />
            {
                (Boolean(data.file) || Boolean(data.link) || (Boolean(data.date))) &&
                <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px 0px', gap: 10 }}>
                    <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                        <LinkDownloadItem
                            readOnly={readOnly}
                            data={data.file}
                            type='file'
                        />
                        <LinkDownloadItem
                            readOnly={readOnly}
                            data={data.link}
                            type='link'
                        />
                        <LinkDownloadItem
                            readOnly={readOnly}
                            data={data.poll}
                            type='poll'
                        />
                    </div>
                    {
                        data.status.enabled && !isFeedback &&
                        <ParticipationIndicator
                            isLoading={isLoading.likesParticipation}
                            readOnly={readOnly}
                            date={data.date}
                            people={people}
                            participationData={data.participation}
                            handleClick={handleIndicateParticipation}
                            handleDownload={canDownloadParticipationData ? handleDownloadParticipation : null}
                        />
                    }
                </div>
            }
            {
                (data.status.enabled || isFeedback) &&
                <CommentsAndLikesIndicator
                    allowsComments={data.allowsComments}
                    commentsAmount={data.commentsAmount}
                    likes={data.likes}
                    people={people}
                    handleLike={handleLike}
                    handleComments={handleToggleComments}
                    readOnly={readOnly}
                />
            }
            <CommentList
                open={showComments}
                data={publicationComments}
                publicationCode={data.publicationCode}
                privacyUserCodes={data.privacy.userCodes}
                people={people}
                isLoading={isLoading?.comments}
                createCommentAction={actions.createComment}
                updateCommentAction={actions.updateComment}
                deleteCommentAction={actions.deleteComment}
                changeCommentStatusAction={actions.changeCommentStatus}
                isFeedback={isFeedback}
                readOnly={readOnly}
            />
            {
                disableDialogIsOpen &&
                <DisableDialog
                    open={disableDialogIsOpen}
                    title="Desactivar publicación"
                    handleSubmit={handleChangeStatus}
                    handleClose={() => setDisableDialogIsOpen(false)}
                />
            }
            {
                formDialogIsOpen &&
                <FormDialog
                    open={formDialogIsOpen}
                    data={data}
                    polls={polls}
                    goals={goals}
                    modeList={modeList}
                    typeList={typeList}
                    groups={groups}
                    tasks={tasks}
                    people={people}
                    createPublicationAction={actions.createPublication}
                    updatePublicationAction={actions.updatePublication}
                    isLoading={isLoading?.feed}
                    isFeedback={isFeedback}
                    handleClose={() => setFormDialogIsOpen(false)}
                />
            }
        </>
    )
}

export default Publication;