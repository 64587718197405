import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";

const GoalDialog = (props) => {

    const {
        open,
        goals,
        handleChange,
        handleClose,
    } = props;

    const [value, setValue] = useState('');

    const handleSubmit = () => {
        const goalSelected = goals.find((item) => item.key === value);
        handleChange(`${goalSelected.name} - ${`Fecha de término: ${goalSelected.endDate}`} - ${`% de progreso: ${goalSelected.progress}%`}`, value);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={DialogTransition}
            PaperComponent={PaperDraggable}
            maxWidth={'sm'}
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {'Elegir metas y objetivos'}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink id={`label-goal`}>{"Metas y objetivos"}</InputLabel>
                    <Select
                        value={value}
                        labelId={`label-goal`}
                        id={`select-goal`}
                        onChange={(e) => setValue(e.target.value)}
                        margin={"none"}
                        displayEmpty
                    >
                        <MenuItem
                            className={"whiteText"}
                            value={''}>
                            {'Ninguno'}
                        </MenuItem>
                        {
                            goals?.map(item => (
                                <MenuItem
                                    className={"whiteText"}
                                    key={item.key}
                                    value={item.key}
                                >
                                    {item.name}
                                    <br />
                                    {`Fecha de término: ${item.endDate}`}
                                    <br />
                                    {`% de progreso: ${item.progress}%`}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleSubmit} isAccept text={'Aceptar'} />
            </DialogActions>
        </Dialog>
    )
}

export default GoalDialog;