import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    timeFormatter,
    dateValueGetter,
    timeValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Código del feedback',
            field: 'publicationCode',
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Quién lo envía',
            field: 'ownerName',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Quién lo recibe',
            field: 'receiverName',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Estado',
            field: 'status',
            filter: "agSetColumnFilter",
            cellRenderer: "FeedbackStatus",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Fecha',
            field: 'date',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Hora',
            field: 'time',
            filter: "agTextColumnFilter",
            cellClass: "timeType",
            valueFormatter: timeFormatter,
            valueGetter: timeValueGetter,
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Referencia',
            field: 'reference',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Texto',
            field: 'text',
            cellRenderer: "RichTextRenderer",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Comentarios',
            field: 'comments',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Fecha de revisión',
            field: 'revisionDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Propósito',
            field: 'type',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Modalidad',
            field: 'mode',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Año',
            field: 'year',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Período',
            field: 'period',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Imagen',
            field: 'image',
            filter: "agTextColumnFilter",
            cellRenderer: "ImageRenderer",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Evidencia',
            field: 'file',
            filter: "agTextColumnFilter",
            cellRenderer: "FileRenderer",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Alcance',
            field: 'privacy',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Grupo',
            field: 'group',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Tarea',
            field: 'task',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Se permiten comentarios',
            field: 'allowsComments',
            filter: "agSetColumnFilter",
        }
    },

    {
        pages: [paths.feedback],
        config: {
            headerName: 'Me gusta',
            field: 'likes',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Rol',
            field: 'role',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Código de quién lo envía',
            field: 'ownerCode',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.feedback],
        config: {
            headerName: 'Código de que quién lo recibe',
            field: 'receiverCode',
            filter: "agTextColumnFilter",
        }
    },
]