import React from "react";
import { Tooltip } from "@material-ui/core";
import ImageIcon from '@material-ui/icons/Image';

const ImageInput = ({ isCreate, handleChange }) => {

    return (
        <>
            <input
                type="file"
                id={isCreate ? "imageUpload" : "imageUploadEdit"}
                accept={'.jpg,.bmp,.png,.jpeg'}
                style={{ display: "none" }}
                onChange={(e) => {
                    handleChange(e.target.files[0]);
                    e.target.value = '';
                }}
            />
            <label htmlFor={isCreate ? "imageUpload" : "imageUploadEdit"} style={{ display: 'flex' }}>
                <Tooltip title="Subir imagen">
                    <ImageIcon style={{ cursor: 'pointer' }} />
                </Tooltip>
            </label>
        </>
    )
}

export default ImageInput;