import React from "react";
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";

const FeedbackInputs = (props) => {

    const {
        modeList,
        typeList,
        formData,
        setFormValue,
    } = props;

    return (
        <Grid container spacing={2}>
            <Grid container item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                    <InputLabel required id={`label-type`}>{"Propósito"}</InputLabel>
                    <Select
                        value={formData.type}
                        labelId={`label-type`}
                        id={`select-type`}
                        onChange={(e) => setFormValue(e.target.value, "type")}
                        margin={"none"}
                    >
                        {
                            typeList?.map(item => (
                                <MenuItem
                                    className={"whiteText"}
                                    key={item.key}
                                    value={item.key}>
                                    {item.value}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid container item xs={12} sm={6} md={4}>
                <TextField
                    required
                    fullWidth
                    margin={"none"}
                    label={'Referencia'}
                    value={formData.reference}
                    onChange={(e) => setFormValue(e.target.value, "reference")}
                    inputProps={{ maxLength: 250 }}
                />
            </Grid>
            <Grid container item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                    <InputLabel required id={`label-mode`}>
                        {formData.status === 'E' ? "¿Cómo has dado el feedback?" : "¿Cómo deseas recibir el feedback?"}
                    </InputLabel>
                    <Select
                        value={formData.mode}
                        labelId={`label-mode`}
                        id={`select-mode`}
                        onChange={(e) => setFormValue(e.target.value, "mode")}
                        margin={"none"}
                    >
                        {
                            modeList?.map(item => (
                                <MenuItem
                                    className={"whiteText"}
                                    key={item.key}
                                    value={item.key}>
                                    {item.value}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default FeedbackInputs;