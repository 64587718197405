import * as actionTypes from "./actionTypes";
import {
  createFeedbackPublicationAPI,
  createFeedbackPublicationCommentAPI,
  deleteFeedbackPublicationAPI,
  deleteFeedbackPublicationCommentAPI,
  getFeedbackAPI,
  getFeedbackGoalsAPI,
  getFeedbackPrivacyOptionsAPI,
  getFeedbackPublicationCommentsAPI,
  getFeedbackPublicationsAPI,
  likeFeedbackPublicationAPI,
  updateFeedbackPublicationAPI,
  updateFeedbackPublicationCommentAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "IN_USE":
      errorString = `No puede eliminarse porque está relacionado con la evaluación de desempeño: ${e.response?.data?.name}`;
      break;
    case "TASK_IN_USE":
      errorString = 'La tarea seleccionada ya está asociada a otro feedback';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: 10000 }));
}

export const getFeedbackAction = (fetchAll) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getFeedbackAPI(`?all=${fetchAll}`);

    let data = response.data && response.data.data;
    let typeList = response.data && response.data.typeList;
    let modeList = response.data && response.data.modeList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        typeList,
        modeList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getFeedbackPrivacyOptionsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_PEOPLE });
  try {
    let response = await getFeedbackPrivacyOptionsAPI();

    let people = response.data && response.data.people;
    let groups = response.data && response.data.groups;
    let tasks = response.data && response.data.tasks;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_FEED_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_PEOPLE_FULFILLED,
      payload: {
        people,
        groups,
        tasks,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_PEOPLE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

// feed /////////////////////////////////////////

export const getFeedbackPublicationsAction = (codes) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_FEED });
  try {
    let response = await getFeedbackPublicationsAPI({ publicationCodes: codes });

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_FEED_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FEED_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_FEED_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const createFeedbackPublicationAction = (dataToSend, showSnackbar) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_FEED_PUBLICATION });
  try {
    let formData = new FormData();

    Object.keys(dataToSend).forEach((key) => {
      if (!dataToSend[key] && key !== 'allowsComments') {
        formData.append(key, '');
        return;
      }

      if (['image', 'file', 'link', 'mode', 'status', 'type', 'reference', 'goal', 'evaluationProcess', 'onboardingAction'].includes(key)) {
        formData.append(key, dataToSend[key]);
        return;
      }

      formData.append(key, JSON.stringify(dataToSend[key]));
    });

    let response = await createFeedbackPublicationAPI(formData);

    let data = response.data && response.data.data;
    let privacyOptions = response.data && response.data.privacyOptions;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_FEED_PUBLICATION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FEED_PUBLICATION_FULFILLED,
      payload: {
        ...privacyOptions,
        data,
      },
    });

    if (showSnackbar) {
      dispatch(openSnackbarAction({ msg: "Publicación creada con éxito", severity: "success" }));
    }
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_FEED_PUBLICATION_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateFeedbackPublicationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_FEED_PUBLICATION });
  try {
    let formData = new FormData();

    Object.keys(dataToSend).forEach((key) => {
      if (!dataToSend[key] && key !== 'keepImage' && key !== 'keepFile' && key !== 'allowsComments') {
        formData.append(key, '');
        return;
      }

      if (['image', 'file', 'link', 'mode', 'status', 'type', 'reference', 'goal', 'publicationCode', 'evaluationProcess', 'onboardingAction'].includes(key)) {
        formData.append(key, dataToSend[key]);
        return;
      }

      formData.append(key, JSON.stringify(dataToSend[key]));
    });

    let response = await updateFeedbackPublicationAPI(formData);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_FEED_PUBLICATION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_FEED_PUBLICATION_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_FEED_PUBLICATION_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteFeedbackPublicationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_FEED_PUBLICATION });
  try {
    let response = await deleteFeedbackPublicationAPI(dataToSend);

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_FEED_PUBLICATION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FEED_PUBLICATION_FULFILLED,
      payload: { data: dataToSend },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_FEED_PUBLICATION_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

// comments //////////////////////////////////

export const getFeedbackPublicationCommentsAction = (publicationCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COMMENTS });
  try {
    let response = await getFeedbackPublicationCommentsAPI(`?publicationCode=${publicationCode}`);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_COMMENTS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_COMMENTS_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_COMMENTS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createFeedbackPublicationCommentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_COMMENT });
  try {
    let response = await createFeedbackPublicationCommentAPI({ ...dataToSend, text: JSON.stringify(dataToSend.text) });

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }
    dispatch({
      type: actionTypes.CREATE_COMMENT_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_FEED_PUBLICATION_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateFeedbackPublicationCommentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_COMMENT });
  try {
    let response = await updateFeedbackPublicationCommentAPI({ ...dataToSend, text: JSON.stringify(dataToSend.text) });

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_COMMENT_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_COMMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteFeedbackPublicationCommentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_COMMENT });
  try {
    let response = await deleteFeedbackPublicationCommentAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_COMMENT_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_COMMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

// likes ////////////////////////////////////////////////////////////////////

export const likeFeedbackPublicationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.LIKE });
  try {
    let response = await likeFeedbackPublicationAPI(dataToSend);

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.LIKE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.LIKE_FULFILLED,
      payload: { data: dataToSend },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.LIKE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

// goals 
export const getFeedbackGoalsAction = (code, statusQueryValue) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_GOALS });
  try {
    let response = await getFeedbackGoalsAPI(`?code=${code}&status=${statusQueryValue}`);

    let goals = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_GOALS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_GOALS_FULFILLED,
      payload: {
        goals,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_GOALS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};