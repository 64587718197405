import * as actionTypes from "./actionTypes";
import {
  changeFeedPublicationCommentStatusAPI,
  changeFeedPublicationStatusAPI,
  createFeedPublicationAPI,
  createFeedPublicationCommentAPI,
  deleteFeedPublicationAPI,
  deleteFeedPublicationCommentAPI,
  downloadFeedPublicationParticipationAPI,
  getFeedInitialDataAPI,
  getFeedPublicationCommentsAPI,
  getFeedPublicationsAPI,
  indicateFeedPublicationParticipationAPI,
  likeFeedPublicationAPI,
  updateFeedPublicationAPI,
  updateFeedPublicationCommentAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { downloadBlob } from "@icarius-utils/download";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getFeedInitialDataAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_INITIAL });
  try {
    let response = await getFeedInitialDataAPI();

    let polls = response.data && response.data.polls;
    let people = response.data && response.data.people;
    let groups = response.data && response.data.groups;
    let tasks = response.data && response.data.tasks;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_FEED_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_INITIAL_FULFILLED,
      payload: {
        polls,
        people,
        groups,
        tasks,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_INITIAL_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

// feed /////////////////////////////////////////

export const getFeedPublicationsAction = (page, search) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_FEED });
  try {
    let response = await getFeedPublicationsAPI(`?page=${page}&search=${search || ''}`);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_FEED_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FEED_FULFILLED,
      payload: {
        data,
        shouldResetData: page === 1,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_FEED_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createFeedPublicationAction = (dataToSend, showSnackbar) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_FEED_PUBLICATION });
  try {
    let formData = new FormData();
    Object.keys(dataToSend).forEach((key) => {
      if (!dataToSend[key] && key !== 'allowsComments') {
        formData.append(key, '');
        return;
      }

      if (key === 'image' || key === 'file' || key === 'link' || key === 'pollCode') {
        formData.append(key, dataToSend[key]);
        return;
      }

      formData.append(key, JSON.stringify(dataToSend[key]));
    });

    let response = await createFeedPublicationAPI(formData);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_FEED_PUBLICATION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FEED_PUBLICATION_FULFILLED,
      payload: { data },
    });

    if (showSnackbar) {
      dispatch(openSnackbarAction({ msg: "Publicación creada con éxito", severity: "success" }));
    }
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_FEED_PUBLICATION_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateFeedPublicationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_FEED_PUBLICATION });
  try {
    let formData = new FormData();
    Object.keys(dataToSend).forEach((key) => {
      if (!dataToSend[key] && key !== 'keepImage' && key !== 'keepFile' && key !== 'allowsComments') {
        formData.append(key, '');
        return;
      }

      if (key === 'image' || key === 'file' || key === 'publicationCode' || key === 'link' || key === 'pollCode') {
        formData.append(key, dataToSend[key]);
        return;
      }

      formData.append(key, JSON.stringify(dataToSend[key]));
    });

    let response = await updateFeedPublicationAPI(formData);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_FEED_PUBLICATION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_FEED_PUBLICATION_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_FEED_PUBLICATION_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteFeedPublicationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_FEED_PUBLICATION });
  try {
    let response = await deleteFeedPublicationAPI(dataToSend);

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_FEED_PUBLICATION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FEED_PUBLICATION_FULFILLED,
      payload: { data: dataToSend },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_FEED_PUBLICATION_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const changeFeedPublicationStatusAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_FEED_PUBLICATION_STATUS });
  try {
    let response = await changeFeedPublicationStatusAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CHANGE_FEED_PUBLICATION_STATUS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CHANGE_FEED_PUBLICATION_STATUS_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CHANGE_FEED_PUBLICATION_STATUS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

// comments //////////////////////////////////

export const getFeedPublicationCommentsAction = (publicationCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_COMMENTS });
  try {
    let response = await getFeedPublicationCommentsAPI(`?publicationCode=${publicationCode}`);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_COMMENTS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_COMMENTS_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_COMMENTS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createFeedPublicationCommentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_COMMENT });
  try {
    let response = await createFeedPublicationCommentAPI({ ...dataToSend, text: JSON.stringify(dataToSend.text) });

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_COMMENT_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_FEED_PUBLICATION_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateFeedPublicationCommentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_COMMENT });
  try {
    let response = await updateFeedPublicationCommentAPI({ ...dataToSend, text: JSON.stringify(dataToSend.text) });

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_COMMENT_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_COMMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteFeedPublicationCommentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_COMMENT });
  try {
    let response = await deleteFeedPublicationCommentAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_COMMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_COMMENT_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_COMMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const changeFeedPublicationCommentStatusAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CHANGE_COMMENT_STATUS });
  try {
    let response = await changeFeedPublicationCommentStatusAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CHANGE_COMMENT_STATUS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CHANGE_COMMENT_STATUS_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CHANGE_COMMENT_STATUS_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

// likes ////////////////////////////////////////////////////////////////////

export const likeFeedPublicationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.LIKE });
  try {
    let response = await likeFeedPublicationAPI(dataToSend);

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.LIKE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.LIKE_FULFILLED,
      payload: { data: dataToSend },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.LIKE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const indicateFeedPublicationParticipationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.INDICATE_PARTICIPATION });
  try {
    let response = await indicateFeedPublicationParticipationAPI(dataToSend);

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.INDICATE_PARTICIPATION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.INDICATE_PARTICIPATION_FULFILLED,
      payload: { data: dataToSend },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.INDICATE_PARTICIPATION_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const downloadFeedPublicationParticipationAction = (codes, title) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_PARTICIPATION });
  try {
    let response = await downloadFeedPublicationParticipationAPI({ codes });

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_PARTICIPATION_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_PARTICIPATION_FULFILLED,
    });

    downloadBlob(response.data, 'xlsx', title || '');

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_PARTICIPATION_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};