import React from "react";
import { Typography } from "@material-ui/core";

const TypeIndicator = ({ data, typeList }) => {

    if (!data || !typeList?.length) return null;

    return (
        <Typography className="whiteText" style={{ fontStyle: 'italic' }}>
            {typeList.find((item) => item.key === data)?.value || ''}
        </Typography>
    )
}

export default TypeIndicator;