import NAME from "./constants";

export const GET_INITIAL = NAME + "/GET_INITIAL";
export const GET_INITIAL_FULFILLED = NAME + "/GET_INITIAL_FULFILLED";
export const GET_INITIAL_REJECTED = NAME + "/GET_INITIAL_REJECTED";

// feed
export const GET_FEED = NAME + "/GET_FEED";
export const GET_FEED_FULFILLED = NAME + "/GET_FEED_FULFILLED";
export const GET_FEED_REJECTED = NAME + "/GET_FEED_REJECTED";

export const CREATE_FEED_PUBLICATION = NAME + "/CREATE_FEED_PUBLICATION";
export const CREATE_FEED_PUBLICATION_FULFILLED = NAME + "/CREATE_FEED_PUBLICATION_FULFILLED";
export const CREATE_FEED_PUBLICATION_REJECTED = NAME + "/CREATE_FEED_PUBLICATION_REJECTED";

export const UPDATE_FEED_PUBLICATION = NAME + "/UPDATE_FEED_PUBLICATION";
export const UPDATE_FEED_PUBLICATION_FULFILLED = NAME + "/UPDATE_FEED_PUBLICATION_FULFILLED";
export const UPDATE_FEED_PUBLICATION_REJECTED = NAME + "/UPDATE_FEED_PUBLICATION_REJECTED";

export const DELETE_FEED_PUBLICATION = NAME + "/DELETE_FEED_PUBLICATION";
export const DELETE_FEED_PUBLICATION_FULFILLED = NAME + "/DELETE_FEED_PUBLICATION_FULFILLED";
export const DELETE_FEED_PUBLICATION_REJECTED = NAME + "/DELETE_FEED_PUBLICATION_REJECTED";

export const CHANGE_FEED_PUBLICATION_STATUS = NAME + "/CHANGE_FEED_PUBLICATION_STATUS";
export const CHANGE_FEED_PUBLICATION_STATUS_FULFILLED = NAME + "/CHANGE_FEED_PUBLICATION_STATUS_FULFILLED";
export const CHANGE_FEED_PUBLICATION_STATUS_REJECTED = NAME + "/CHANGE_FEED_PUBLICATION_STATUS_REJECTED";

// comments
export const GET_COMMENTS = NAME + "/GET_COMMENTS";
export const GET_COMMENTS_FULFILLED = NAME + "/GET_COMMENTS_FULFILLED";
export const GET_COMMENTS_REJECTED = NAME + "/GET_COMMENTS_REJECTED";

export const CREATE_COMMENT = NAME + "/CREATE_COMMENT";
export const CREATE_COMMENT_FULFILLED = NAME + "/CREATE_COMMENT_FULFILLED";
export const CREATE_COMMENT_REJECTED = NAME + "/CREATE_COMMENT_REJECTED";

export const UPDATE_COMMENT = NAME + "/UPDATE_COMMENT";
export const UPDATE_COMMENT_FULFILLED = NAME + "/UPDATE_COMMENT_FULFILLED";
export const UPDATE_COMMENT_REJECTED = NAME + "/UPDATE_COMMENT_REJECTED";

export const DELETE_COMMENT = NAME + "/DELETE_COMMENT";
export const DELETE_COMMENT_FULFILLED = NAME + "/DELETE_COMMENT_FULFILLED";
export const DELETE_COMMENT_REJECTED = NAME + "/DELETE_COMMENT_REJECTED";

export const CHANGE_COMMENT_STATUS = NAME + "/CHANGE_COMMENT_STATUS";
export const CHANGE_COMMENT_STATUS_FULFILLED = NAME + "/CHANGE_COMMENT_STATUS_FULFILLED";
export const CHANGE_COMMENT_STATUS_REJECTED = NAME + "/CHANGE_COMMENT_STATUS_REJECTED";

// likes
export const LIKE = NAME + "/LIKE";
export const LIKE_FULFILLED = NAME + "/LIKE_FULFILLED";
export const LIKE_REJECTED = NAME + "/LIKE_REJECTED";

// participation
export const INDICATE_PARTICIPATION = NAME + "/INDICATE_PARTICIPATION";
export const INDICATE_PARTICIPATION_FULFILLED = NAME + "/INDICATE_PARTICIPATION_FULFILLED";
export const INDICATE_PARTICIPATION_REJECTED = NAME + "/INDICATE_PARTICIPATION_REJECTED";

export const DOWNLOAD_PARTICIPATION = NAME + "/DOWNLOAD_PARTICIPATION";
export const DOWNLOAD_PARTICIPATION_FULFILLED = NAME + "/DOWNLOAD_PARTICIPATION_FULFILLED";
export const DOWNLOAD_PARTICIPATION_REJECTED = NAME + "/DOWNLOAD_PARTICIPATION_REJECTED";

export const RESET_STATE = NAME + "/RESET_STATE";