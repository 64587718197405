import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { isValidUrl } from "@icarius-utils/types";
import DialogTransition from "@icarius-common/dialogTransition";

const LinkDialog = (props) => {

    const {
        open,
        initialValue,
        handleChange,
        handleClose,
    } = props;

    const [value, setValue] = useState(initialValue);
    const dispatch = useDispatch();

    const dataIsValid = () => {
        const urlToUse = value.includes('http') ? value : `http://${value}`;
        return !value || isValidUrl(urlToUse);
    }

    const handleSubmit = () => {
        if (dataIsValid()) {
            handleChange(value);
            handleClose();
            return;
        }

        dispatch(openSnackbarAction({ msg: 'Ingrese un link válido', severity: 'error' }));
    }

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={'sm'}
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {'Ingresar link'}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
                <TextField
                    autoFocus
                    fullWidth
                    label="Ingrese un link"
                    value={value}
                    onChange={(e) => setValue(e.target.value.replace(/\s/g, ""))}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}
                />
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleSubmit} isAccept text={'Guardar'} />
            </DialogActions>
        </Dialog>
    )
}

export default LinkDialog;