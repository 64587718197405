import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Radio,
    DialogActions,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import { PRIVACY_NAMES } from "@icarius-pages/feed/constants";
import LockIcon from '@material-ui/icons/Lock';
import PublicIcon from '@material-ui/icons/Public';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ProfileImg from "@icarius-common/profileImg";
import UserSelectionColumn from "@icarius-common/userSelectionDialog/components/userSelectionColumn";
import SelectedUsersColumn from "@icarius-common/userSelectionDialog/components/selectedUsersColumn";
import useHandleUserSelection from "@icarius-common/userSelectionDialog/useHandleUserSelection";
import DialogTransition from "@icarius-common/dialogTransition";

const PRIVACY_TYPES = [
    {
        key: 'U',
        name: PRIVACY_NAMES.U,
        description: 'Será visualizada por todos los colaboradores.',
        icon: <PublicIcon />,
    },
    {
        key: 'P',
        name: PRIVACY_NAMES.P,
        description: 'Solo la persona que selecciones.',
        icon: <LockIcon />,
    },
    {
        key: 'L',
        name: PRIVACY_NAMES.L,
        description: 'Solo la lista de personas que selecciones.',
        icon: <SettingsIcon />,
    },
    {
        key: 'G',
        name: PRIVACY_NAMES.G,
        description: 'Solo las personas dentro del grupo que selecciones.',
        icon: <GroupIcon />
    },
    {
        key: 'T',
        name: PRIVACY_NAMES.T,
        description: 'Solo las personas que compartan la tarea que selecciones.',
        icon: <AssignmentIcon />,
    },
];

const PrivacyDialog = (props) => {

    const {
        open,
        data,
        people,
        groups,
        tasks,
        isFeedback,
        statusToCreate,
        handleChange,
        handleClose,
    } = props;

    const dispatch = useDispatch();

    const [step, setStep] = useState(0);
    const [typeSelected, setTypeSelected] = useState(data.type);
    const [groupOrTaskSelected, setGroupOrTaskSelected] = useState(data?.groupOrTaskSelected);

    const {
        keysSelected,
        checkedList,
        handleSelection,
        resetUsersSelected,
    } = useHandleUserSelection(people, data.userCodes, typeSelected === 'P');

    const handleSubmit = () => {
        if (!typeSelected) {
            dispatch(openSnackbarAction({ msg: 'Debe seleccionar una opción', severity: "error", duration: 10000 }));
            return;
        }

        if (step === 1 && typeSelected !== 'U' && !keysSelected?.length && !groupOrTaskSelected) {
            dispatch(openSnackbarAction({ msg: 'Debe seleccionar una opción', severity: "error", duration: 10000 }));
            return;
        }

        if (step === 0) {
            // si es publico, mando userCodes vacio
            if (typeSelected === 'U') {
                handleChange({
                    type: typeSelected,
                    userCodes: [],
                });
                handleClose();
                return;
            }

            setStep(1);
            return;
        }

        // si es grupo o tarea obtengo los key del seleccionado
        if (groupOrTaskSelected) {
            const usersFromGroupOrTask = (typeSelected === 'T' ? tasks : groups).find((item) => item.key === groupOrTaskSelected).participants;
            handleChange({
                type: typeSelected,
                userCodes: usersFromGroupOrTask,
                groupOrTaskSelected: groupOrTaskSelected,
                [typeSelected === 'T' ? 'task' : 'group']: groupOrTaskSelected,
            });
            handleClose();
            return;
        }

        // si no es grupo o tarea, uso keysSelected
        handleChange({
            type: typeSelected,
            userCodes: keysSelected,
        });
        handleClose();
    }

    const handleCancel = () => {
        if (step === 0) {
            handleClose();
            return;
        }

        setStep(0);
    }

    const handleChangeType = (option) => {
        if (option !== typeSelected) {
            setTypeSelected(option);
            setGroupOrTaskSelected(null);
            resetUsersSelected();
        }
    }

    const canAssociateWithTask = isFeedback && statusToCreate === 'E';

    const filteredTasks = tasks.filter((item) => {
        if (!canAssociateWithTask) return true;
        return item.isOwner && !item.inUse;
    })

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {isFeedback ? `Quién ${statusToCreate === 'E' ? 'recibe el feedback' : 'debe darte feedback'}` : 'Quién puede ver la publicación'}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
                <Typography className="whiteText" style={{ marginBottom: 20, fontSize: 18 }}>
                    {
                        isFeedback
                            ? 'Tu feedback podrá ser visualizado por quienes tú indiques'
                            : `Tu publicación se mostrará en Social, y podrá ser visualizada por quienes tú indiques.`
                    }
                </Typography>
                <div style={{ maxHeight: 390, overflow: 'auto' }}>
                    {
                        step === 0 && PRIVACY_TYPES
                            .map((item) => {
                                if (item.key === 'U' && isFeedback) return null;
                                if (item.key === 'P' && !people?.length) return null;
                                if (item.key === 'L' && !people?.length) return null;
                                if (item.key === 'G' && !groups?.length) return null;
                                if (item.key === 'T' && !filteredTasks?.length) return null;
                                return (
                                    <div
                                        key={item.key}
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            margin: '20px 0px'
                                        }}
                                        onClick={() => handleChangeType(item.key)}
                                    >
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginRight: 20 }}>
                                                {item.icon}
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography className="whiteText" style={{ fontSize: 20 }}>
                                                    {item.name}
                                                </Typography>
                                                <Typography className="whiteText">
                                                    {item.description}
                                                </Typography>
                                                {
                                                    item.key === 'T' && canAssociateWithTask &&
                                                    <Typography className="whiteText" style={{ fontStyle: 'italic' }}>
                                                        {'Con esta opción, el feedback y la tarea quedarán vinculados'}
                                                    </Typography>
                                                }
                                            </div>
                                        </div>
                                        <Radio checked={item.key === typeSelected} />
                                    </div>
                                )
                            })
                    }
                    {
                        step === 1 && ['P', 'L'].includes(typeSelected) &&
                        <div style={{ display: 'flex', gap: 10 }}>
                            {/* Columna 1 - seleccionados */}
                            <SelectedUsersColumn
                                showAmount
                                title={'Seleccionados'}
                                employees={checkedList}
                                handleClick={handleSelection}
                            />
                            {/* Columna 2 - todos los usuarios */}
                            <UserSelectionColumn
                                employees={checkedList}
                                handleClick={handleSelection}
                            />
                        </div>
                    }
                    {
                        step === 1 && ['G', 'T'].includes(typeSelected) &&
                        <div>
                            <Typography className="whiteText" style={{ marginBottom: 20, weight: 600, fontSize: 18 }}>
                                {typeSelected === 'T' ? 'Selecciona la tarea que puede visualizar' : 'Selecciona el grupo que puede visualizar'}
                            </Typography>
                            {
                                (typeSelected === 'T' ? filteredTasks : groups)
                                    .map((item) => {
                                        return (
                                            <div
                                                key={item.key}
                                                style={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    margin: '20px 0px'
                                                }}
                                                onClick={() => setGroupOrTaskSelected(item.key)}
                                            >
                                                <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                                    {
                                                        typeSelected === 'G' &&
                                                        <div style={{ marginRight: 20 }}>
                                                            <ProfileImg
                                                                image={item.img}
                                                                size={30}
                                                            />
                                                        </div>
                                                    }
                                                    <Typography className="whiteText" style={{ fontSize: 20 }}>
                                                        {item.name}
                                                    </Typography>
                                                </div>
                                                <Radio checked={item.key === groupOrTaskSelected} />
                                            </div>
                                        )
                                    })
                            }
                        </div>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleCancel} text={step === 0 ? 'Cancelar' : 'Volver'} />
                <ButtonDialogAction onClick={handleSubmit} isAccept text={'Aceptar'} />
            </DialogActions>
        </Dialog>
    )
}

export default PrivacyDialog;