import React, { useMemo } from "react";
import {
    Checkbox,
    FormControlLabel,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useSelector } from "react-redux";
import { getAvatar, getUserData } from "src/app/selectors";
import ProfileImg from "@icarius-common/profileImg";
import SendIcon from '@material-ui/icons/Send';
import { SaveIcon } from "@icarius-icons/index";
import TextEditor from "@icarius-common/textEditor";
import Image from "./components/image";
import EventDate from "./components/eventDate";
import LinkDownloadPollItem from "./components/linkDownloadPollItem";
import PrivacyInput from "./components/privacyInput";
import ImageInput from "./components/imageInput";
import EventDateInput from "./components/eventDateInput";
import FileInput from "./components/fileInput";
import LinkInput from "./components/linkInput";
import useHandleForm from "./useHandleForm";
import FeedbackInputs from "./components/feedbackInputs";
import PollInput from "./components/pollInput";
import GoalInput from "./components/goalInput";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";

const Form = (props) => {

    const {
        data,
        modeList,
        typeList,
        polls,
        groups,
        goals,
        tasks,
        people,
        statusToCreate,
        preselectedData,
        createPublicationAction,
        updatePublicationAction,
        getGoalsAction,
        handleClose,
        isFeedback,
        handleAfterCreateCallback,
        hideGoals,
    } = props;

    const dispatch = useDispatch();
    const avatar = useSelector(getAvatar);
    const { code } = useSelector(getUserData);

    const create = (dataToSend) => {
        dispatch(createPublicationAction(dataToSend))
            .then((res) => {
                if (res?.status === 200 && handleClose) {
                    if (handleAfterCreateCallback) {
                        handleAfterCreateCallback(res);
                    }
                    handleClose();
                }
            });
    }

    const update = (dataToSend) => {
        dispatch(updatePublicationAction(dataToSend))
            .then((res) => {
                if (res?.status === 200 && handleClose) {
                    handleClose();
                }
            });
    }

    const getGoals = (code, status) => {
        if (getGoalsAction) {
            dispatch(getGoalsAction(code, status));
        }
    }

    const openValidationError = () => {
        if (isFeedback) {
            dispatch(openSnackbarAction({
                msg: 'Debe ingresar los campos obligatorios y elegir los destinatarios',
                severity: "error",
                duration: 15000,
            }));
            return;
        }
        dispatch(openSnackbarAction({ msg: 'Debe ingresar el texto de la publicación', severity: "error", duration: 10000 }));
    }

    const {
        isCreate,
        formData,
        setFormValue,
        setReferenceTypeGoal,
        submit,
    } = useHandleForm(data, create, update, openValidationError, isFeedback, statusToCreate, getGoals, typeList, preselectedData);

    const suggestionOptions = useMemo(() => {
        // si no tiene privacy.userCodes y es feedback, es porque no se seteo privacidad. Si no es feedback (es social), es publico y van todos
        if (!formData.privacy.userCodes.length) return isFeedback ? [] : people;
        return people?.filter((item) => [...formData.privacy.userCodes, code].includes(item.key));
    }, [isFeedback, people, formData.privacy.userCodes, code]);

    const submitButtonText = (() => {
        if (!isCreate) return 'Guardar';
        if (isFeedback) return statusToCreate === 'E' ? 'Dar feedback' : 'Solicitar feedback';
        return 'Publicar';
    })();

    const handleChangeGoal = (newRef, newGoal) => {
        const newType = typeList.find((item) => item.key === 'TLM') ? 'TLM' : '';
        setReferenceTypeGoal(newRef, newType, newGoal);
    }

    return (
        <>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 10 }}>
                <ProfileImg
                    image={avatar}
                    size={80}
                />
                <div style={{ flex: 1, minWidth: 200 }}>
                    <TextEditor
                        initialValue={isCreate ? '' : data.text}
                        placeholder={'Ingrese texto'}
                        suggestionOptions={suggestionOptions}
                        handleChange={(text, htmlText) => setFormValue({ text, htmlText }, 'text')}
                        helperText={"Para que tu comentario también llegue por correo, menciona a los destinatarios con @"}
                    />
                </div>
            </div>
            <Image
                data={formData.image}
                readOnly={false}
                handleDelete={() => setFormValue(null, 'image')}
            />
            <EventDate
                data={formData.date}
                readOnly={!isCreate}
                isFeedback={isFeedback}
                handleDelete={() => setFormValue(null, 'date')}
            />
            {
                (Boolean(formData.file) || Boolean(formData.link) || Boolean(formData.pollCode)) &&
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10, paddingTop: 20 }}>
                    <LinkDownloadPollItem
                        handleDelete={() => setFormValue(null, 'file')}
                        data={formData.file}
                        type='file'
                    />
                    <LinkDownloadPollItem
                        handleDelete={() => setFormValue('', 'link')}
                        data={formData.link}
                        type='link'
                    />
                    {
                        !isFeedback &&
                        <LinkDownloadPollItem
                            handleDelete={() => setFormValue('', 'pollCode')}
                            data={polls?.find((item) => item.key === formData.pollCode)}
                            type='poll'
                            readOnly={!isCreate}
                        />
                    }
                </div>
            }
            {
                isFeedback &&
                <div style={{ padding: '20px 0px' }}>
                    <FeedbackInputs
                        modeList={modeList}
                        typeList={typeList}
                        formData={formData}
                        setFormValue={setFormValue}
                    />
                </div>
            }
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    gap: 15,
                    marginTop: 10,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 15,
                    }}
                >
                    {
                        isCreate &&
                        <PrivacyInput
                            data={formData.privacy}
                            groups={groups}
                            tasks={tasks}
                            people={people}
                            handleChange={(value) => setFormValue(value, "privacy")}
                            isFeedback={isFeedback}
                            statusToCreate={statusToCreate}
                        />
                    }
                    {
                        isCreate &&
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(e) => setFormValue(e.target.checked, "allowsComments")}
                                    checked={formData.allowsComments}
                                />
                            }
                            label="Permitir comentarios"
                        />
                    }
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 15,
                    }}
                >
                    {
                        isFeedback &&
                        !hideGoals &&
                        !preselectedData?.goal &&
                        formData.privacy.type === 'P' &&
                        Boolean(formData.privacy.userCodes.length) &&
                        <GoalInput
                            goals={goals}
                            handleChange={(newRef, newGoal) => handleChangeGoal(newRef, newGoal)}
                        />
                    }
                    {
                        !isFeedback && !formData.date && isCreate &&
                        <PollInput
                            data={formData.pollCode}
                            polls={polls}
                            handleChange={(value) => setFormValue(value, 'pollCode')}
                        />
                    }
                    <ImageInput
                        isCreate={isCreate}
                        handleChange={(value) => setFormValue(value, 'image')}
                    />
                    {
                        ((isCreate && !formData.pollCode) || isFeedback) &&
                        <EventDateInput
                            data={formData.date}
                            handleChange={(value) => setFormValue(value, 'date')}
                            isFeedback={isFeedback}
                        />
                    }
                    <FileInput
                        isCreate={isCreate}
                        handleChange={(value) => setFormValue(value, 'file')}
                        isFeedback={isFeedback}
                    />
                    {
                        !isFeedback &&
                        <LinkInput
                            data={formData.link}
                            handleChange={(value) => setFormValue(value, 'link')}
                        />
                    }
                    <ButtonDialogAction isAccept onClick={submit} text={submitButtonText} startIcon={isCreate ? <SendIcon /> : <SaveIcon />} />
                </div>
            </div>
        </>
    )
}

export default Form;