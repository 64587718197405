import React from "react";
import Form from "./index";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import Loader from "@icarius-common/loader";
import DialogTransition from "@icarius-common/dialogTransition";

const FormDialog = (props) => {

    const {
        open,
        preselectedData,
        data,
        goals,
        modeList,
        typeList,
        groups,
        polls,
        tasks,
        people,
        statusToCreate,
        createPublicationAction,
        updatePublicationAction,
        getGoalsAction,
        isLoading,
        handleClose,
        isFeedback,
        handleAfterCreateCallback,
        hideGoals,
    } = props;

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {`${Boolean(data) ? 'Editar' : 'Crear'} ${isFeedback ? 'feedback' : 'publicación'}`}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
                <Loader open={isLoading} />
                <Form
                    data={data}
                    preselectedData={preselectedData}
                    modeList={modeList}
                    typeList={typeList}
                    goals={goals}
                    polls={polls}
                    groups={groups}
                    tasks={tasks}
                    people={people}
                    statusToCreate={statusToCreate}
                    createPublicationAction={createPublicationAction}
                    updatePublicationAction={updatePublicationAction}
                    getGoalsAction={getGoalsAction}
                    handleClose={handleClose}
                    handleAfterCreateCallback={handleAfterCreateCallback}
                    isFeedback={isFeedback}
                    hideGoals={hideGoals}
                />
            </DialogContent>
        </Dialog>
    )
}

export default FormDialog;