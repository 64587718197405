import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbackPurposeAction } from "../actions";
import { getLoading, getData } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import paths from "@icarius-localization/paths";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";

const FeedbackPurpose = ({ history, location }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const locationState = useRef(location?.state);
  const hasSetUserFromLocation = useRef(false);
  
  // para determinar a que pantalla hacer el goBack y limpiar el state
  useEffect(() => {
    if (locationState.current && !Boolean(hasSetUserFromLocation.current)) {
      history.push({
        pathname: paths.feedbackPurpose,
        state: null,
      });
      
      hasSetUserFromLocation.current = true;
    }
  }, [history])
  
  const dispatch = useDispatch();
  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getFeedbackPurposeAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  const handleGoBack = () => {
    history.push(locationState.current?.from || paths.organizationalDefinitions);
  }

  return (
    <ABMLayout
      title={"Propósitos de feedback"}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={"Nuevo propósito de feedback"}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
      showNoData={getDataIsEmpty()}
      noDataTitle={"No se encontraron propósitos de feedback"}
      noDataCreateTitle={"Nuevo propósito de feedback"}
      noDataOnClick={() => setCreateIsOpen(true)}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            color={color}
            data={null}
            closeNew={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        data && data.map((item) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
              <DataItem
                data={item}
                color={color}
              />
            </Grid>
          )
        })
      }
    </ABMLayout>
  )
}

export default FeedbackPurpose;