import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";

const DisableDialog = (props) => {

    const {
        open,
        title,
        handleSubmit,
        handleClose,
    } = props;

    const [value, setValue] = useState('');

    const dispatch = useDispatch();

    const submit = () => {
        if (!value) {
            dispatch(openSnackbarAction({ msg: 'Debe ingresar el motivo', severity: "error", duration: 10000 }));
            return;
        }

        handleSubmit(value);
        handleClose();
    }

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={'sm'}
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {title}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    fullWidth
                    label="Ingrese motivo"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleClose} text={'Cancelar'} />
                <ButtonDialogAction onClick={submit} isAccept text={'Aceptar'} />
            </DialogActions>
        </Dialog>
    )
}

export default DisableDialog;