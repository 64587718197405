import React from "react";
import CommonPage from "@icarius-common/commonPage";
import HomeHeader from "@icarius-pages/home/components/homeHeader";
import { Button, Card, InputAdornment, TextField, Typography } from "@material-ui/core";
import { Virtuoso } from 'react-virtuoso'
import Form from "./form/index";
import useFeed from "./useFeed";
import Publication from "./publication";
import useResetStateByKey from "@icarius-utils/hooks/useResetStateByKey";
import { AddIcon, SearchIcon } from "@icarius-icons/index";
import { Alert } from "@material-ui/lab";

const LoadMoreListFooter = ({ context }) => {
  const { handleFetchNextPage, isLoadingFeed, isLoadingInitialData } = context;

  if (isLoadingInitialData) return null;

  return (
    <div
      style={{
        padding: '2rem',
        display: 'flex',
        justifyContent: 'center',
        height: 110,
      }}
    >
      {
        !isLoadingFeed &&
        <Button
          variant="outlined"
          className="whiteText"
          startIcon={<AddIcon />}
          onClick={() => handleFetchNextPage()}
        >
          Cargar más
        </Button>
      }
    </div>
  )
}

const Feed = () => {

  const {
    isBlocked,
    searchValue,
    data,
    polls,
    people,
    groups,
    tasks,
    comments,
    isLoading,
    actions,
    handlers,
  } = useFeed();

  const {
    key,
    resetState,
  } = useResetStateByKey();

  return (
    <CommonPage
      isNotGridPage
      isLoading={isLoading.initialData || isLoading.feed}
    >
      <HomeHeader />
      <div style={{ margin: '0 auto', padding: 10, maxWidth: 1000, marginBottom: 10 }}>
        <Card style={{ padding: 20, marginBottom: 10 }}>
          <TextField
            placeholder={"Buscar..."}
            value={searchValue}
            onChange={(e) => handlers.setSearchValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handlers.handleSearch();
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" onClick={handlers.handleSearch} style={{ cursor: 'pointer' }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Card>
        {
          !isBlocked &&
          <Card style={{ padding: 20, marginBottom: 10 }}>
            <Form
              key={key}
              polls={polls}
              groups={groups}
              tasks={tasks}
              people={people}
              createPublicationAction={actions.createPublication}
              updatePublicationAction={actions.updatePublication}
              handleClose={resetState}
            />
          </Card>
        }
        {
          isBlocked &&
          <div style={{ marginBottom: 10 }}>
            <Alert variant="filled" severity="error">
              Usuario bloqueado: no puedes crear ni interactuar con otras publicaciones porque has sido bloqueado.
            </Alert>
          </div>
        }
        {
          !data.length && !isLoading.feed &&
          <Typography
            className="whiteText"
            align="center"
            style={{
              fontSize: 32,
              fontWeight: 700,
              marginTop: 30,
            }}
          >
            No se encontraron publicaciones
          </Typography>
        }
        <Virtuoso
          useWindowScroll
          style={{ height: 300 }}
          data={data}
          components={{ Footer: LoadMoreListFooter }}
          context={{
            isLoadingFeed: isLoading.feed,
            isLoadingInitialData: isLoading.initialData,
            handleFetchNextPage: handlers.handleFetchNextPage,
          }}
          itemContent={(index, data) => {
            return (
              <div
                key={data.publicationCode}
                style={{ paddingTop: 20 }}
              >
                <Card style={{ padding: 20, paddingTop: 10 }}>
                  <Publication
                    data={data}
                    polls={polls}
                    people={people}
                    groups={groups}
                    tasks={tasks}
                    comments={comments}
                    isLoading={isLoading}
                    actions={actions}
                    forceReadOnly={isBlocked}
                  />
                </Card>
              </div>
            )
          }}
        />
      </div>
    </CommonPage>
  )
}

export default Feed;
