import NAME from "./constants";

export const getIsLoadingInitialData = store => store[NAME].isLoadingInitialData;
export const getIsLoadingFeed = store => store[NAME].isLoadingFeed;
export const getIsLoadingComments = store => store[NAME].isLoadingComments;
export const getIsLoadingLikesParticipation = store => store[NAME].isLoadingLikesParticipation;
export const getData = store => store[NAME].data;
export const getPolls = store => store[NAME].polls;
export const getPeople = store => store[NAME].people;
export const getGroups = store => store[NAME].groups;
export const getTasks = store => store[NAME].tasks;
export const getComments = store => store[NAME].comments;