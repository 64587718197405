import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import PollIcon from '@material-ui/icons/Poll';
import { useSelector } from "react-redux";
import { getUserRole } from "src/app/selectors";
import PollDialog from "./pollDialog";

const PollInput = (props) => {

    const {
        data,
        polls,
        handleChange,
    } = props;

    const [pollDialogIsOpen, setPollDialogIsOpen] = useState(false);

    const role = useSelector(getUserRole);

    if (role !== "E" || !polls?.length) return null;

    return (
        <>
            <Tooltip title="Asociar a encuesta">
                <PollIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setPollDialogIsOpen(true)}
                />
            </Tooltip>
            {
                pollDialogIsOpen &&
                <PollDialog
                    open={pollDialogIsOpen}
                    initialValue={data}
                    polls={polls}
                    handleChange={(value) => handleChange(value)}
                    handleClose={() => setPollDialogIsOpen(false)}
                />
            }
        </>
    )
}

export default PollInput;