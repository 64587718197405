import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import CommentItem from "./commentItem";
import CommentForm from "./form";
import useResetStateByKey from "@icarius-utils/hooks/useResetStateByKey";
import ProfileImg from "@icarius-common/profileImg";
import { useSelector } from "react-redux";
import { getAvatar } from "src/app/selectors";

const List = (props) => {

    const {
        open,
        data,
        publicationCode,
        privacyUserCodes,
        people,
        isLoading,
        createCommentAction,
        updateCommentAction,
        deleteCommentAction,
        changeCommentStatusAction,
        isFeedback,
        readOnly,
    } = props;

    const [showEveryComment, setShowEveryComment] = useState(false);

    const {
        key,
        resetState,
    } = useResetStateByKey();

    const avatar = useSelector(getAvatar);

    if (!open) return null;

    return (
        <div style={{ marginTop: 20 }}>
            {
                !readOnly &&
                <div style={{ display: 'flex', gap: 10 }}>
                    <ProfileImg
                        image={avatar}
                        size={45}
                    />
                    <CommentForm
                        key={key}
                        privacyUserCodes={privacyUserCodes}
                        publicationCode={publicationCode}
                        people={people}
                        createCommentAction={createCommentAction}
                        updateCommentAction={updateCommentAction}
                        handleClose={resetState}
                        isFeedback={isFeedback}
                    />
                </div>
            }
            {
                data.slice(0, showEveryComment ? data.length : 2).map((item) => {
                    return (
                        <CommentItem
                            key={item.internalCode}
                            data={item}
                            isLoading={isLoading?.comments}
                            publicationCode={publicationCode}
                            privacyUserCodes={privacyUserCodes}
                            people={people}
                            deleteCommentAction={deleteCommentAction}
                            changeCommentStatusAction={changeCommentStatusAction}
                            createCommentAction={createCommentAction}
                            updateCommentAction={updateCommentAction}
                            isFeedback={isFeedback}
                            readOnly={readOnly}
                        />
                    )
                })
            }
            {
                !Boolean(data.length) &&
                <Typography
                    align="center"
                    className="whiteText"
                    style={{ fontSize: 20 }}
                >
                    No se encontraron comentarios
                </Typography>
            }
            {
                !showEveryComment && data.length > 2 &&
                <Typography
                    align="center"
                    className="whiteText"
                    style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        marginTop: 20,
                    }}
                    onClick={() => setShowEveryComment(true)}
                >
                    Ver todos
                </Typography>
            }
        </div>
    )
}

export default List;