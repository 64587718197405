import React from "react";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent, Card, CardContent, Grid, Typography } from "@material-ui/core";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition.js";
import { withStyles } from "@material-ui/core/styles";
import BarChart from "./BarChart";
import { useSelector } from "react-redux";
import { getAppColor, getUserData } from "src/app/selectors";
import { getTheme } from "@icarius-pages/login/selectors";
import ProfileImg from "@icarius-common/profileImg";

const GraphsDialog = (props) => {

  const {
    open,
    data,
    people,
    fetchAll,
    handleClose,
  } = props;

  const color = useSelector(getAppColor);
  const { theme } = useSelector(getTheme);
  const userData = useSelector(getUserData);

  const ModifiedCard = withStyles({
    root: {
      marginBottom: "1vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:last-child": {
        marginLeft: 5,
      },
      backgroundColor: theme === "light" && "var(--secondaryBackgroundColor)",
    },
  })(Card);

  const ModifiedCardContent = withStyles({
    root: {
      width: "100%",
      padding: 4,
      "&:last-child": {
        paddingBottom: 4,
      },
    },
  })(CardContent);

  const pieData = data.reduce((auxData, item) => {
    auxData[item.type] = (auxData[item.type] || 0) + 1;
    return auxData;
  }, {});

  const barData = ["Entregado", "Recibido", "Solicitado", "Dar feedback"].reduce((auxData, item) => {
    auxData[item] = data.filter((publication) => publication.status === item).length;
    return auxData;
  }, {});

  const peopleData = data
    .filter((item) => {
      if (fetchAll) return true;
      return item.ownerCode !== userData.code;
    })
    .reduce((auxData, item) => {
      auxData[item.ownerCode] = (auxData[item.ownerCode] || 0) + 1;
      return auxData;
    }, {})

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Gráficos`}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <div style={{ width: "100%" }} className="container relative flex flex-row justify-between items-center">
          <Grid container direction="row">
            <Grid item xs={12} sm={6}>
              <ModifiedCard>
                <ModifiedCardContent>
                  <BarChart
                    dataType={"bar"}
                    color={color}
                    theme={theme}
                    type={"bar"}
                    data={barData}
                    title={"Estado"}
                  />
                </ModifiedCardContent>
              </ModifiedCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ModifiedCard>
                <ModifiedCardContent>
                  <BarChart
                    dataType={"pie"}
                    color={color}
                    theme={theme}
                    type={"pie"}
                    title={"Propósito"}
                    data={pieData}
                  />
                </ModifiedCardContent>
              </ModifiedCard>
            </Grid>
            <div style={{ margin: '10px auto' }}>
              <Typography
                className="whiteText"
                align="center"
                style={{ fontSize: 20, marginBottom: 20, textDecoration: 'underline', textAlign: 'center' }}
              >
                {`Top 10 personas que ${fetchAll ? '' : 'me'} han dado más feedback`}
              </Typography>
              {
                Object.keys(peopleData)
                  .sort((a, b) => {
                    if (peopleData[a] < peopleData[b]) return 1;
                    if (peopleData[a] > peopleData[b]) return -1;
                    
                    const nameA = people.find((item) => item.key === a).name;
                    const nameB = people.find((item) => item.key === b).name;

                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                  })
                  .slice(0, 10)
                  .map((item) => {
                    const person = people.find((peopleItem) => peopleItem.key === item);

                    return (
                      <div
                        key={item}
                        style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 10, marginBottom: 20 }}
                      >
                        <ProfileImg
                          size={30}
                          image={person.img}
                        />
                        <div>
                          <Typography className="whiteText" style={{ fontWeight: 600 }}>
                            {`${peopleData[item]} - ${person.name}`}
                          </Typography>
                          <Typography className="whiteText">
                            {`${person.position}, ${person.workplace}`}
                          </Typography>
                        </div>
                      </div>
                    )
                  })
              }
            </div>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default GraphsDialog;
