import React, { useState } from "react";
import CommonPage from "@icarius-common/commonPage";
import useFeedback from "./useFeedback";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import ImageRenderer from "@icarius-table/renderersAndFilters/imageRenderer";
import FeedbackStatus from "@icarius-table/renderersAndFilters/feedbackStatus";
import FileRenderer from "@icarius-table/renderersAndFilters/fileRenderer";
import { useHistory } from "react-router-dom";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import TimelineIcon from '@material-ui/icons/Timeline';
import StyledHelpMenu from "@icarius-common/styledMenu";
import FormDialog from "@icarius-pages/feed/components/form/formDialog";
import Feed from "./feed";
import Timeline from "./timeline";
import GraphsDialog from "./graphsDialog";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import { useRef } from "react";
import RichTextRenderer from "@icarius-table/renderersAndFilters/richTextRenderer";

const Feedback = () => {

  const history = useHistory();
  const ownGridRef = useRef(); // tengo que usar una ref propia para el aggrid porque tengo 3 commonPage y al salir y volver se pierden las ref internas

  const {
    userData,
    gridData,
    feedData,
    modeList,
    typeList,
    people,
    goals,
    groups,
    tasks,
    comments,
    isLoading,
    actions,
    state,
    handlers,
  } = useFeedback();

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('FEEDBACK');

  const [anchorEl, setAnchorEl] = useState(null);

  const customHeader = () => (
    <TemplatesHeader
      gridRef={ownGridRef.current}
      user={user}
      templates={templates}
      selectedTemplate={selectedTemplate}
      getRole={getRole}
      handleImportTemplates={handleImportTemplates}
      handleExportTemplates={handleExportTemplates}
      handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
      handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
      handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
      handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
    />
  )

  const createButton = () => (
    <>
      <CustomIconButton
        title={'Crear'}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        type={"add"}
      />
      <StyledHelpMenu
        anchorEl={anchorEl}
        isOpen={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItemWithIcon
          text={'Dar feedback'}
          onClick={() => {
            setAnchorEl(null);
            handlers.handleOpenFormDialog('E');
          }}
        />
        <MenuItemWithIcon
          text={'Solicitar feedback'}
          onClick={() => {
            setAnchorEl(null);
            handlers.handleOpenFormDialog('S');
          }}
        />
      </StyledHelpMenu>
    </>
  )

  const viewButton = () => (
    <CustomIconButton
      title={'Ver'}
      onClick={() => handlers.handleOpenPublicationFeed(ownGridRef.current)}
      type={'preview'}
    />

  )
  const deleteButton = () => (
    <CustomIconButton
      title={'Eliminar'}
      onClick={() => handlers.handleDelete(ownGridRef.current)}
      type={'delete'}
    />
  )

  const timelineButton = () => (
    <CustomIconButton
      title={'Línea de tiempo'}
      onClick={() => handlers.handleOpenTimeline(ownGridRef.current)}
    >
      <TimelineIcon />
    </CustomIconButton>
  )

  const graphicsButton = () => (
    <CustomIconButton
      title={'Estadísticas gráficas'}
      onClick={() => handlers.handleOpenGraphicsDialog(ownGridRef.current)}
      type={'graphics'}
    />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={'Volver'}
      onClick={() => history.push(paths.myDesktop)}
      type={"goBack"}
    />
  )

  const child = () =>
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(ownGridRef.current, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
      {
        Boolean(state.graphicsData) &&
        <GraphsDialog
          open={Boolean(state.graphicsData)}
          data={state.graphicsData}
          fetchAll={state.fetchAll}
          people={people}
          handleClose={handlers.handleCloseGraphicsDialog}
        />
      }
      {
        state.formDialogIsOpen &&
        <FormDialog
          open={state.formDialogIsOpen}
          data={null}
          goals={goals}
          modeList={modeList}
          typeList={typeList}
          groups={groups}
          tasks={tasks}
          people={people}
          statusToCreate={state.statusToCreate}
          createPublicationAction={actions.createPublication}
          updatePublicationAction={actions.updatePublication}
          getGoalsAction={actions.getGoals}
          isLoading={isLoading.feed}
          handleClose={() => handlers.handleCloseFormDialog(!state.feedIsOpen)}
          isFeedback
        />
      }
    </>

  return (
    <>
      {
        state.timelineIsOpen &&
        <Timeline
          modeList={modeList}
          typeList={typeList}
          people={people}
          groups={groups}
          tasks={tasks}
          data={feedData}
          comments={comments}
          handleGoBack={() => {
            handlers.handleCloseTimeline();
            setTimeout(() => {
              handleSelectTemplateAndUpdateGrid(selectedTemplate?.Code, ownGridRef?.current)
            }, 700);
          }
          }
        />
      }
      {
        state.feedIsOpen &&
        <Feed
          title={state.fetchAll ? 'Feedbacks seleccionados' : 'Mis feedbacks'}
          isLoading={isLoading}
          actions={actions}
          groups={groups}
          goals={goals}
          tasks={tasks}
          modeList={modeList}
          typeList={typeList}
          people={people}
          data={feedData}
          comments={comments}
          handleGoBack={() => {
            handlers.handleClosePublicationFeed();
            setTimeout(() => {
              handleSelectTemplateAndUpdateGrid(selectedTemplate?.Code, ownGridRef?.current)
            }, 700);
          }
          }
        />
      }
      {
        !(state.feedIsOpen || state.timelineIsOpen) &&
        <CommonPage
          isLoading={isLoadingTemplates || isLoading.gridData || isLoading.privacyData || isLoading.feed || isLoading.comments}
          title={'Feedback'}
          gridTitle={'Feedback'}
          columnDefPage={paths.feedback}
          rowData={gridData}
          menuItems={[goBackButton, createButton, viewButton, deleteButton, timelineButton, graphicsButton]}
          customHeader={customHeader}
          hasExpand
          hasHelp
          hasSwitch={userData.level === 'E'}
          handleSwitchClick={handlers.handleSwitchFetchAll}
          switchLabelOff={'Solo entregados'}
          switchLabelOn={'Entregados y recibidos'}
          switchValue={state.fetchAll}
          frameworkComponents={{ ImageRenderer, FileRenderer, FeedbackStatus, RichTextRenderer }}
          ownGridRef={ownGridRef}
        >
          {child}
        </CommonPage>
      }
    </>
  )
}

export default Feedback;
