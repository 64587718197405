import NAME from "./constants";

export const getIsLoadingGridData = store => store[NAME].isLoadingGridData;
export const getIsLoadingPrivacyData = store => store[NAME].isLoadingPrivacyData;
export const getIsLoadingFeed = store => store[NAME].isLoadingFeed;
export const getIsLoadingComments = store => store[NAME].isLoadingComments;
export const getIsLoadingLikesParticipation = store => store[NAME].isLoadingLikesParticipation;
export const getGridData = store => store[NAME].gridData;
export const getFeedData = store => store[NAME].feedData;
export const getTypeList = store => store[NAME].typeList;
export const getModeList = store => store[NAME].modeList;
export const getPeople = store => store[NAME].people;
export const getGroups = store => store[NAME].groups;
export const getTasks = store => store[NAME].tasks;
export const getComments = store => store[NAME].comments;
export const getGoals = store => store[NAME].goals;