import React from "react";
import { Alert } from "@material-ui/lab";

const DisabledIndicator = ({ data, isComment }) => {

    if (!data || data?.enabled) return null;

    return (
        <div style={{ marginBottom: 10 }}>
            <Alert variant="filled" severity="error">
                <>
                    {`Solo tu puedes ver ${isComment ? 'este comentario' : 'esta publicación'} ya que ha sido ${isComment ? 'desactivado' : 'desactivada'} por ${data.responsibleName}.`}
                    <br />
                    {`Motivo: ${data.reason}`}
                </>
            </Alert>
        </div>
    )
}

export default DisabledIndicator;