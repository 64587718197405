import React from "react";
import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import DescriptionIcon from '@material-ui/icons/Description';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PollIcon from '@material-ui/icons/Poll';
import { downloadFromURL, previewFromURL } from "@icarius-utils/download";
import { CloseIcon, GetAppIcon, VisibilityIcon } from "@icarius-icons/index";
import { getFileExtension, getFileSizeString } from "@icarius-utils/fileUpload";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import { openPollAction } from "@icarius-common/poll/actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const LinkDownloadPollItem = (props) => {

    const {
        data,
        type,
        readOnly, // en true impide eliminar y descargar. Si se necesita que se pueda descargar pero no eliminar, pasar readOnly false y handleDelete null
        handleDelete,
    } = props;

    const dispatch = useDispatch();

    const handleClick = () => {
        if (readOnly) return;
        switch (type) {
            case 'file': {
                downloadFile();
                break;
            }
            case 'link': {
                openLink();
                break;
            }
            case 'poll': {
                openPoll();
                break;
            }
            default: break;
        }
    }


    const previewFile = () => {
        if (readOnly) return;
        const url = data.path ? `${RESOURCES_ENDPOINT}${data.path}` : URL.createObjectURL(data);

        if (getFileExtension(url) !== 'pdf') {
            dispatch(openSnackbarAction({ msg: 'Solo se pueden previsualizar archivos con formato .pdf', severity: 'warning' }));
            return;
        }

        previewFromURL(url);
    }

    const downloadFile = () => {
        if (readOnly) return;
        const url = data.path ? `${RESOURCES_ENDPOINT}${data.path}` : URL.createObjectURL(data);
        downloadFromURL(url, data.name);
    }

    const openLink = () => {
        if (readOnly) return;
        const formattedLink = data.includes('http') ? data : `http://${data}`;
        window.open(formattedLink, "_blank");
    }

    const openPoll = () => {
        if (readOnly) return;
        dispatch(openPollAction(data.key, data.value, data.type));
    }

    if (!data) return null;

    const label = (() => {
        switch (type) {
            case 'file': return data.name;
            case 'link': return data;
            case 'poll': return data.value;
            default: return '';
        }
    })();

    return (
        <div
            style={{
                padding: 10,
                display: 'flex',
                alignItems: 'center',
                background: 'var(--mainBackgroundColor)',
                border: '1px solid var(--medalTableBorder)',
                borderRadius: 10,
            }}
        >
            {type === 'file' && <DescriptionIcon />}
            {type === 'poll' && <PollIcon />}
            <div
                style={{ paddingLeft: 10, paddingRight: 15, cursor: readOnly ? '' : 'pointer' }}
                onClick={handleClick}
            >
                <Typography className="whiteText" style={{ textDecoration: 'underline' }}>
                    {label}
                </Typography>
                {
                    type === 'file' &&
                    <Typography className="whiteText" variant="caption">
                        {getFileSizeString(data.size)}
                    </Typography>
                }
            </div>
            {
                !readOnly &&
                <div style={{ display: 'flex', gap: 10 }}>
                    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
                        {type === 'file' && <GetAppIcon />}
                        {type === 'link' && <OpenInNewIcon />}
                    </div>
                    {
                        type === 'file' &&
                        <div onClick={previewFile} style={{ cursor: 'pointer' }}>
                            <VisibilityIcon />
                        </div>
                    }
                    {
                        Boolean(handleDelete) &&
                        <CloseIcon onClick={handleDelete} style={{ cursor: 'pointer' }} />
                    }
                </div>
            }
        </div>
    )
}

export default LinkDownloadPollItem;
