import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getGridData,
  getFeedData,
  getIsLoadingGridData,
  getIsLoadingPrivacyData,
  getIsLoadingFeed,
  getIsLoadingLikesParticipation,
  getIsLoadingComments,
  getPeople,
  getGroups,
  getTasks,
  getComments,
  getTypeList,
  getModeList,
  getGoals,
} from "../selectors";
import { useDispatch } from "react-redux";
import {
  getFeedbackPrivacyOptionsAction,
  getFeedbackPublicationsAction,
  createFeedbackPublicationAction,
  updateFeedbackPublicationAction,
  getFeedbackPublicationCommentsAction,
  createFeedbackPublicationCommentAction,
  updateFeedbackPublicationCommentAction,
  likeFeedbackPublicationAction,
  getFeedbackAction,
  deleteFeedbackPublicationAction,
  deleteFeedbackPublicationCommentAction,
  getFeedbackGoalsAction,
} from "../actions";
import { RESET_FEED_AND_COMMENTS, RESET_STATE } from "../actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getUserData } from "src/app/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";

const useFeedback = () => {

  const dispatch = useDispatch();
  const [fetchAll, setFetchAll] = useState(false);

  const [feedIsOpen, setFeedIsOpen] = useState(false);
  const [timelineIsOpen, setTimelineIsOpen] = useState(false);
  const [graphicsData, setGraphicsData] = useState(null);

  const [formDialogIsOpen, setFormDialogIsOpen] = useState(false);
  const [statusToCreate, setStatusToCreate] = useState(null);

  useEffect(() => {
    dispatch(getFeedbackAction(fetchAll));
  }, [dispatch, fetchAll])

  useEffect(() => {
    dispatch(getFeedbackPrivacyOptionsAction());

    return () => dispatch({ type: RESET_STATE });
  }, [dispatch])

  const userData = useSelector(getUserData);

  const gridData = useSelector(getGridData);
  const feedData = useSelector(getFeedData);

  const typeList = useSelector(getTypeList);
  const modeList = useSelector(getModeList);
  const people = useSelector(getPeople);
  const groups = useSelector(getGroups);
  const tasks = useSelector(getTasks);
  const comments = useSelector(getComments);
  const goals = useSelector(getGoals);

  const isLoadingGridData = useSelector(getIsLoadingGridData);
  const isLoadingPrivacyData = useSelector(getIsLoadingPrivacyData);
  const isLoadingFeed = useSelector(getIsLoadingFeed);
  const isLoadingLikesParticipation = useSelector(getIsLoadingLikesParticipation);
  const isLoadingComments = useSelector(getIsLoadingComments);

  const handleSwitchFetchAll = () => setFetchAll((prev) => !prev);

  const handleDelete = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (selectedRows[0].ownerCode !== userData.code) {
      dispatch(openSnackbarAction({ msg: 'No puede eliminar el feedback seleccionado porque usted no lo ha enviado', severity: "warning" }));
      return;
    }

    const message = (() => {
      if (!selectedRows[0].associatedWithTask) return `¿Desea borrar el feedback?`;
      return (
        <>
          {`Este feedback está vinculado a la tarea: ${selectedRows[0].task}`}
          <br />
          <br />
          Si elimina el feedback, también se eliminará la tarea completa y todo lo que esta contenga (Actividades, avances, adjuntos y comentarios)
        </>
      )
    })();

    dispatch(openDialogAction({
      msg: message,
      title: 'Atención',
      onConfirm: () => dispatch(deleteFeedbackPublicationAction({ publicationCode: selectedRows[0].publicationCode })),
    }));
  }

  const handleOpenGraphicsDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    const uniquePublications = selectedRows.filter((value, index, array) => array.findIndex((item) => item.publicationCode === value.publicationCode) === index);
    setGraphicsData(uniquePublications);
  }

  const handleCloseGraphicsDialog = () => {
    setGraphicsData(null);
  }

  const handleOpenTimeline = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    const codes = selectedRows.map((item) => item.publicationCode);

    dispatch(getFeedbackPublicationsAction(codes))
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(getFeedbackPublicationCommentsAction(codes[0]))
            .then((resp) => {
              if (resp.status === 200) {
                setTimelineIsOpen(true);
              }
            })
        }
      })
  }

  const handleCloseTimeline = () => {
    setTimelineIsOpen(false);
    dispatch({ type: RESET_FEED_AND_COMMENTS });
  }

  const handleOpenPublicationFeed = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    const codes = selectedRows.map((item) => item.publicationCode)
      .filter((value, index, array) => array.indexOf(value) === index) // borrar duplicados

    dispatch(getFeedbackPublicationsAction(codes))
      .then((resp) => {
        if (resp.status === 200) {
          setFeedIsOpen(true);
        }
      })
  }

  const handleClosePublicationFeed = () => {
    setFeedIsOpen(false);
    dispatch({ type: RESET_FEED_AND_COMMENTS });
    dispatch(getFeedbackAction(fetchAll));
  }

  const handleOpenFormDialog = (newStatus) => {
    setStatusToCreate(newStatus);
    setFormDialogIsOpen(true);
  }

  const handleCloseFormDialog = (shouldFetchGridData) => {
    setFormDialogIsOpen(false);
    setStatusToCreate(null);

    if (shouldFetchGridData) {
      dispatch(getFeedbackAction(fetchAll));
    }
  }

  const actions = {
    getPrivacyOptions: getFeedbackPrivacyOptionsAction,
    getPublications: getFeedbackPublicationsAction,
    createPublication: createFeedbackPublicationAction,
    updatePublication: updateFeedbackPublicationAction,
    deletePublication: deleteFeedbackPublicationAction,
    likePublication: likeFeedbackPublicationAction,
    getComments: getFeedbackPublicationCommentsAction,
    createComment: createFeedbackPublicationCommentAction,
    updateComment: updateFeedbackPublicationCommentAction,
    deleteComment: deleteFeedbackPublicationCommentAction,
    getGoals: getFeedbackGoalsAction,
  }

  const isLoading = {
    gridData: isLoadingGridData,
    privacyData: isLoadingPrivacyData,
    feed: isLoadingFeed,
    likesParticipation: isLoadingLikesParticipation,
    comments: isLoadingComments,
  }

  const state = {
    feedIsOpen,
    timelineIsOpen,
    graphicsData,
    formDialogIsOpen,
    statusToCreate,
    fetchAll,
  }

  const handlers = {
    handleOpenPublicationFeed,
    handleClosePublicationFeed,
    handleOpenFormDialog,
    handleCloseFormDialog,
    handleOpenTimeline,
    handleCloseTimeline,
    handleOpenGraphicsDialog,
    handleCloseGraphicsDialog,
    handleSwitchFetchAll,
    handleDelete,
  }

  return {
    userData,
    gridData,
    feedData,
    modeList,
    typeList,
    people,
    goals,
    groups,
    tasks,
    comments,
    isLoading,
    actions,
    state,
    handlers,
  }
}

export default useFeedback;
