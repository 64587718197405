import React from "react";
import { Chip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const EventIndicator = ({ date, label }) => {

    const color = useSelector(getAppColor);

    if (!date) return null;

    return (
        <div style={{ margin: '10px 0px', display: 'flex', flexWrap: 'wrap', gap: 5 }}>
            <Chip
                label={label}
                style={{ background: color, color: 'white' }}
            />
            <Chip
                label={`Fecha: ${date}`}
                className="whiteText"
                style={{ background: 'var(--mainBackgroundColor)', border: '1px solid var(--medalTableBorder)' }}
            />
        </div>
    )
}

export default EventIndicator;