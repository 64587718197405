import React, { useState } from "react";
import {
    Tooltip,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import TodayIcon from '@material-ui/icons/Today';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const EventDateInput = ({ data, handleChange, isFeedback }) => {

    const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
    
    return (
        <>
            <Tooltip title={isFeedback ? "Fecha de revisión" : "Evento"}>
                <TodayIcon style={{ cursor: 'pointer' }} onClick={() => setDatePickerIsOpen(true)} />
            </Tooltip>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                    open={datePickerIsOpen}
                    value={data}
                    onClose={() => setDatePickerIsOpen(false)}
                    minDate={new Date()}
                    clearable
                    clearLabel={getLocalizedString("clear")}
                    cancelLabel={getLocalizedString("cancel")}
                    okLabel={getLocalizedString("ok")}
                    onChange={(e) => handleChange(e)}
                    TextFieldComponent={() => null}
                />
            </MuiPickersUtilsProvider>
        </>
    )
}

export default EventDateInput;