import React from "react";
import { Button } from "@material-ui/core";
import { SendIcon } from "@icarius-icons/index";
import TextEditor from "@icarius-common/textEditor";
import useHandleForm from "./useHandleForm";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useMemo } from "react";
import { getUserData } from "src/app/selectors";

const Form = (props) => {

    const {
        data,
        publicationCode,
        privacyUserCodes,
        readOnly,
        people,
        createCommentAction,
        updateCommentAction,
        handleClose,
        isFeedback,
    } = props;

    const dispatch = useDispatch();
    const { code } = useSelector(getUserData);

    const create = (dataToSend) => {
        dispatch(createCommentAction({ publicationCode, ...dataToSend }))
        handleClose();
    }

    const update = (dataToSend) => {
        dispatch(updateCommentAction(dataToSend))
        handleClose();
    }

    const openValidationError = () => {
        dispatch(openSnackbarAction({ msg: 'El comentario no puede estar vacío', severity: "error", duration: 10000 }));
    }

    const suggestionOptions = useMemo(() => {
        // si no tiene privacyUserCodes y es feedback, es porque no se seteo privacidad. Si no es feedback (es social), es publico y van todos
        if (!privacyUserCodes.length) return isFeedback ? [] : people;
        return people?.filter((item) => [...privacyUserCodes, code].includes(item.key));
    }, [isFeedback, people, privacyUserCodes, code])

    const {
        isCreate,
        setFormValue,
        submit,
    } = useHandleForm(data, create, update, openValidationError);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                gap: 10,
                flex: 1,
                alignItems: 'flex-end',
            }}
        >
            <div style={{ width: '100%' }}>
                <TextEditor
                    initialValue={isCreate ? '' : data.text}
                    placeholder={isCreate ? 'Crear comentario' : 'Editar comentario'}
                    handleChange={(text, htmlText) => setFormValue({ text, htmlText }, 'text')}
                    suggestionOptions={suggestionOptions}
                    readOnly={readOnly}
                    helperText={readOnly ? "" : "Para que tu comentario también llegue por correo, menciona a los destinatarios con @"}
                />
            </div>
            {
                !readOnly &&
                <Button
                    onClick={submit}
                    style={{ height: 40, width: 140, border: '1px solid var(--icons)' }}
                    variant="outlined"
                    className="whiteText"
                    startIcon={<SendIcon />}
                >
                    {isCreate ? 'Comentar' : 'Guardar'}
                </Button>
            }
        </div>
    )
}

export default Form;