import React from "react";
import CommonPage from "@icarius-common/commonPage";
import { Card } from "@material-ui/core";
import { Virtuoso } from 'react-virtuoso'
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import Publication from "@icarius-pages/feed/components/publication";

const Feed = (props) => {

  const {
    title,
    isLoading,
    actions,
    groups,
    goals,
    tasks,
    modeList,
    typeList,
    people,
    data,
    comments,
    handleGoBack,
  } = props;

  const goBackButton = () => (
    <CustomIconButton
      title={'Volver'}
      onClick={handleGoBack}
      type={"goBack"}
    />
  )

  return (
    <div style={{ margin: '0 auto', padding: 10, maxWidth: 1000, marginBottom: 10 }}>
      <CommonPage
        isNotGridPage
        title={title}
        isLoading={isLoading.feed}
        menuItems={[goBackButton]}
      >
        <Virtuoso
          useWindowScroll
          style={{ height: 300 }}
          data={data}
          itemContent={(index, data) => {
            return (
              <div
                key={data.publicationCode}
                style={{ paddingTop: 20 }}
              >
                <Card style={{ padding: 20, paddingTop: 10 }}>
                  <Publication
                    data={data}
                    groups={groups}
                    goals={goals}
                    tasks={tasks}
                    modeList={modeList}
                    typeList={typeList}
                    people={people}
                    comments={comments}
                    isLoading={isLoading}
                    actions={actions}
                    isFeedback
                  />
                </Card>
              </div>
            )
          }}
        />
      </CommonPage>
    </div>
  )
}

export default Feed;