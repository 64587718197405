import React from "react";
import { Chip } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { useDispatch } from "react-redux";
import { openPollAction } from "@icarius-common/poll/actions";

const PollIndicator = ({ data, readOnly }) => {

    const color = useSelector(getAppColor);
    const dispatch = useDispatch();

    const handleClick = () => {
        if (readOnly) return;
        dispatch(openPollAction(data.key, data.value, data.type));
    }

    if (!data) return null;

    return (
        <div
            style={{ margin: '10px 0px', display: 'flex', flexWrap: 'wrap', gap: 5 }}
            onClick={handleClick}
        >
            <Chip
                label={'Encuesta'}
                style={{ background: color, color: 'white', cursor: readOnly ? '' : 'pointer' }}
            />
            <Chip
                label={data.value}
                className="whiteText"
                style={{ background: 'var(--mainBackgroundColor)', border: '1px solid var(--medalTableBorder)', cursor: readOnly ? '' : 'pointer' }}
            />
        </div>
    )
}

export default PollIndicator;