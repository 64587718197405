import { useSelector } from "react-redux";
import {
  getData,
  getIsLoadingInitialData,
  getIsLoadingFeed,
  getIsLoadingLikesParticipation,
  getIsLoadingComments,
  getPeople,
  getGroups,
  getTasks,
  getComments,
  getPolls,
} from "../selectors";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  getFeedInitialDataAction,
  getFeedPublicationsAction,
  createFeedPublicationAction,
  updateFeedPublicationAction,
  deleteFeedPublicationAction,
  changeFeedPublicationStatusAction,
  getFeedPublicationCommentsAction,
  createFeedPublicationCommentAction,
  updateFeedPublicationCommentAction,
  deleteFeedPublicationCommentAction,
  changeFeedPublicationCommentStatusAction,
  likeFeedPublicationAction,
  indicateFeedPublicationParticipationAction,
  downloadFeedPublicationParticipationAction,
} from "../actions";
import { RESET_STATE } from "../actionTypes";
import { getIsBlockedSocial } from "src/app/selectors";

const useFeed = () => {

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const oldSearchValue = useRef('');

  useEffect(() => {
    dispatch(getFeedInitialDataAction());
    dispatch(getFeedPublicationsAction(1));
  }, [dispatch])

  useEffect(() => {
    return () => dispatch({ type: RESET_STATE });
  }, [dispatch])

  const isBlocked = useSelector(getIsBlockedSocial);
  const data = useSelector(getData);

  const polls = useSelector(getPolls);
  const people = useSelector(getPeople);
  const groups = useSelector(getGroups);
  const tasks = useSelector(getTasks);
  const comments = useSelector(getComments);

  const isLoadingInitialData = useSelector(getIsLoadingInitialData);
  const isLoadingFeed = useSelector(getIsLoadingFeed);
  const isLoadingLikesParticipation = useSelector(getIsLoadingLikesParticipation);
  const isLoadingComments = useSelector(getIsLoadingComments);

  const handleSearch = () => {
    let resetPage = false;

    // si es distinto al anterior (o si es vacio), resetear el paginado
    if ((searchValue && searchValue !== oldSearchValue.current) || !searchValue) {
      oldSearchValue.current = searchValue || '';
      resetPage = true;
    }

    handleFetchNextPage(resetPage);
  }

  const handleFetchNextPage = (resetPage) => {
    let pageToUse = resetPage ? 0 : page;

    dispatch(getFeedPublicationsAction(pageToUse + 1, searchValue))
      .then((res) => {
        if (res?.status === 200 && res?.data?.data?.length) {
          setPage(pageToUse + 1);
        }
      });
  };

  const actions = {
    getPrivacyOptions: getFeedInitialDataAction,
    getPublications: getFeedPublicationsAction,
    createPublication: createFeedPublicationAction,
    updatePublication: updateFeedPublicationAction,
    deletePublication: deleteFeedPublicationAction,
    changePublicationStatus: changeFeedPublicationStatusAction,
    likePublication: likeFeedPublicationAction,
    indicatePublicationParticipation: indicateFeedPublicationParticipationAction,
    getComments: getFeedPublicationCommentsAction,
    createComment: createFeedPublicationCommentAction,
    updateComment: updateFeedPublicationCommentAction,
    deleteComment: deleteFeedPublicationCommentAction,
    changeCommentStatus: changeFeedPublicationCommentStatusAction,
    downloadPublicationParticipation: downloadFeedPublicationParticipationAction,
  }

  const isLoading = {
    initialData: isLoadingInitialData,
    feed: isLoadingFeed,
    likesParticipation: isLoadingLikesParticipation,
    comments: isLoadingComments,
  }

  const handlers = {
    handleFetchNextPage,
    handleSearch,
    setSearchValue,
  }

  return {
    isBlocked,
    searchValue,
    data,
    polls,
    people,
    groups,
    tasks,
    comments,
    isLoading,
    actions,
    handlers,
  }
}

export default useFeed;
