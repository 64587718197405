import React from "react";
import ProfileImg from "@icarius-common/profileImg";
import { Chip, Typography } from "@material-ui/core";
import { roleNames } from "@icarius-utils/constants";
import DotsMenu from "@icarius-common/dotsMenu";
import moment from "moment";
import { PRIVACY_NAMES } from "@icarius-pages/feed/constants";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import PeopleDialog from "./peopleDialog";
import { useState } from "react";

const Header = (props) => {

    const {
        image,
        name,
        role,
        date,
        time,
        edited,
        privacy,
        hasMenu,
        menuOptions,
        people,
        statusText,
        modeText,
    } = props;

    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const color = useSelector(getAppColor);

    const handleOpenDialog = () => {
        if (privacy.type === 'U') return;
        setDialogIsOpen(true)
    }

    return (
        <>
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 10 }}>
                    <ProfileImg
                        image={image}
                        size={30}
                    />
                    <Typography className="whiteText" style={{ fontWeight: 600 }}>
                        {name}
                    </Typography>
                    <Chip
                        size="small"
                        label={roleNames[role]}
                        style={{
                            background: color,
                            color: 'white',
                        }}
                    />
                    <Typography
                        className="whiteText firstLetterUppercase"
                        style={{
                            fontSize: 14,
                            fontWeight: 500,
                        }}
                    >
                        {moment(`${date} ${time}`, 'DD-MM-YYYY hh:mm').from(moment(new Date()))}
                    </Typography>
                    {
                        Boolean(privacy) &&
                        <Typography
                            className="whiteText firstLetterUppercase"
                            style={{
                                fontSize: 14,
                                fontWeight: 500,
                                textDecoration: privacy.type !== 'U' && 'underline',
                                cursor: privacy.type !== 'U' && 'pointer',
                            }}
                            onClick={handleOpenDialog}
                        >
                            {PRIVACY_NAMES[privacy.type]}
                        </Typography>
                    }
                    {
                        Boolean(edited) &&
                        <Chip
                            size="small"
                            className="whiteText"
                            label={'Editado'}
                            style={{
                                background: 'var(--mainBackgroundColor)',
                                border: '1px solid var(--medalTableBorder)',
                            }}
                        />
                    }
                    {
                        Boolean(statusText) &&
                        <Chip
                            size="small"
                            className="whiteText"
                            label={statusText}
                            style={{
                                background: 'var(--mainBackgroundColor)',
                                border: '1px solid var(--medalTableBorder)',
                            }}
                        />
                    }
                    {
                        Boolean(modeText) &&
                        <Chip
                            size="small"
                            className="whiteText"
                            label={modeText}
                            style={{
                                background: 'var(--mainBackgroundColor)',
                                border: '1px solid var(--medalTableBorder)',
                            }}
                        />
                    }
                </div>
                {
                    hasMenu &&
                    <DotsMenu>
                        {menuOptions}
                    </DotsMenu>
                }
            </div>
            {
                dialogIsOpen &&
                <PeopleDialog
                    open={dialogIsOpen}
                    data={privacy.userCodes}
                    people={people}
                    title={'Quién puede ver'}
                    handleClose={() => setDialogIsOpen(false)}
                />
            }
        </>
    )
}

export default Header;