import NAME from "./constants";

export const GET = NAME + "/GET";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";
export const GET_REJECTED = NAME + "/GET_REJECTED";

// people
export const GET_PEOPLE = NAME + "/GET_PEOPLE";
export const GET_PEOPLE_FULFILLED = NAME + "/GET_PEOPLE_FULFILLED";
export const GET_PEOPLE_REJECTED = NAME + "/GET_PEOPLE_REJECTED";

// goals
export const GET_GOALS = NAME + "/GET_GOALS";
export const GET_GOALS_FULFILLED = NAME + "/GET_GOALS_FULFILLED";
export const GET_GOALS_REJECTED = NAME + "/GET_GOALS_REJECTED";

// feedback
export const GET_FEED = NAME + "/GET_FEED";
export const GET_FEED_FULFILLED = NAME + "/GET_FEED_FULFILLED";
export const GET_FEED_REJECTED = NAME + "/GET_FEED_REJECTED";

export const CREATE_FEED_PUBLICATION = NAME + "/CREATE_FEED_PUBLICATION";
export const CREATE_FEED_PUBLICATION_FULFILLED = NAME + "/CREATE_FEED_PUBLICATION_FULFILLED";
export const CREATE_FEED_PUBLICATION_REJECTED = NAME + "/CREATE_FEED_PUBLICATION_REJECTED";

export const UPDATE_FEED_PUBLICATION = NAME + "/UPDATE_FEED_PUBLICATION";
export const UPDATE_FEED_PUBLICATION_FULFILLED = NAME + "/UPDATE_FEED_PUBLICATION_FULFILLED";
export const UPDATE_FEED_PUBLICATION_REJECTED = NAME + "/UPDATE_FEED_PUBLICATION_REJECTED";

export const DELETE_FEED_PUBLICATION = NAME + "/DELETE_FEED_PUBLICATION";
export const DELETE_FEED_PUBLICATION_FULFILLED = NAME + "/DELETE_FEED_PUBLICATION_FULFILLED";
export const DELETE_FEED_PUBLICATION_REJECTED = NAME + "/DELETE_FEED_PUBLICATION_REJECTED";

// comments
export const GET_COMMENTS = NAME + "/GET_COMMENTS";
export const GET_COMMENTS_FULFILLED = NAME + "/GET_COMMENTS_FULFILLED";
export const GET_COMMENTS_REJECTED = NAME + "/GET_COMMENTS_REJECTED";

export const CREATE_COMMENT = NAME + "/CREATE_COMMENT";
export const CREATE_COMMENT_FULFILLED = NAME + "/CREATE_COMMENT_FULFILLED";
export const CREATE_COMMENT_REJECTED = NAME + "/CREATE_COMMENT_REJECTED";

export const UPDATE_COMMENT = NAME + "/UPDATE_COMMENT";
export const UPDATE_COMMENT_FULFILLED = NAME + "/UPDATE_COMMENT_FULFILLED";
export const UPDATE_COMMENT_REJECTED = NAME + "/UPDATE_COMMENT_REJECTED";

export const DELETE_COMMENT = NAME + "/DELETE_COMMENT";
export const DELETE_COMMENT_FULFILLED = NAME + "/DELETE_COMMENT_FULFILLED";
export const DELETE_COMMENT_REJECTED = NAME + "/DELETE_COMMENT_REJECTED";

// likes
export const LIKE = NAME + "/LIKE";
export const LIKE_FULFILLED = NAME + "/LIKE_FULFILLED";
export const LIKE_REJECTED = NAME + "/LIKE_REJECTED";

export const RESET_FEED_AND_COMMENTS = NAME + "/RESET_FEED_AND_COMMENTS";

export const RESET_STATE = NAME + "/RESET_STATE";