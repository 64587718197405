import React from "react";
import { Radio, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";
import { useState } from "react";
import PeopleDialog from "./peopleDialog";

const ParticipationIndicator = (props) => {

    const {
        isLoading,
        readOnly,
        date,
        participationData,
        people,
        handleClick,
        handleDownload,
    } = props;

    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const userData = useSelector(getUserData);

    if (!date) return null;

    return (
        <>
            <div
                style={{
                    padding: 10,
                    background: 'var(--mainBackgroundColor)',
                    border: '1px solid var(--medalTableBorder)',
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Typography className="whiteText" style={{ marginRight: 5 }}>
                    Asistiré al evento:
                </Typography>
                <Typography
                    className="whiteText"
                    style={{ textDecoration: 'underline', cursor: 'pointer', marginRight: 10 }}
                    onClick={() => setDialogIsOpen(true)}
                >
                    {participationData.length}
                </Typography>
                <div
                    style={{ display: 'flex', alignItems: 'center', marginRight: 10, cursor: readOnly ? '' : 'pointer' }}
                    onClick={() => handleClick(true)}
                >
                    <Radio
                        disabled={readOnly}
                        color='primary'
                        checked={participationData.includes(userData.code)}
                    />
                    <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                        {'Si'}
                    </Typography>
                </div>
                <div
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => handleClick(false)}
                >
                    <Radio
                        disabled={readOnly}
                        color='primary'
                        checked={!participationData.includes(userData.code)}
                    />
                    <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                        {'No'}
                    </Typography>
                </div>
            </div>
            {
                dialogIsOpen &&
                <PeopleDialog
                    isLoading={isLoading}
                    open={dialogIsOpen}
                    data={participationData}
                    people={people}
                    title={'Asistencia al evento'}
                    handleDownload={handleDownload}
                    handleClose={() => setDialogIsOpen(false)}
                />
            }
        </>
    )
}

export default ParticipationIndicator;