import React from "react";
import { Tooltip, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PeopleDialog from "./peopleDialog";
import { useState } from "react";

const CommentsAndLikesIndicator = (props) => {

    const {
        allowsComments,
        commentsAmount,
        likes,
        people,
        handleLike,
        handleComments,
        readOnly,
    } = props;

    const [likesDialogIsOpen, setLikesDialogIsOpen] = useState(false);

    const userData = useSelector(getUserData);

    return (
        <>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end', paddingTop: 10, gap: 20 }}>
                {
                    Boolean(allowsComments) &&
                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <Typography className="whiteText">
                            {commentsAmount}
                        </Typography>
                        <Tooltip title='Comentarios'>
                            <ChatBubbleOutlineIcon
                                onClick={handleComments}
                                style={{ cursor: 'pointer' }}
                            />
                        </Tooltip>
                    </div>
                }
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                    <Tooltip title='Ver me gusta'>
                        <Typography
                            className="whiteText"
                            style={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => setLikesDialogIsOpen(true)}
                        >
                            {likes.length}
                        </Typography>
                    </Tooltip>
                    <Tooltip title='Me gusta'>
                        <div style={{ cursor: !readOnly && 'pointer', display: 'flex' }} onClick={!readOnly ? handleLike : null}>
                            {
                                likes.includes(userData.code) ?
                                    <FavoriteIcon />
                                    :
                                    <FavoriteBorderIcon />
                            }
                        </div>
                    </Tooltip>
                </div>
            </div>
            {
                likesDialogIsOpen &&
                <PeopleDialog
                    open={likesDialogIsOpen}
                    data={likes}
                    people={people}
                    title={'Me gusta'}
                    handleClose={() => setLikesDialogIsOpen(false)}
                />
            }
        </>
    )
}

export default CommentsAndLikesIndicator;