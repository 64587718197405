import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import LinkDialog from "./linkDialog";
import { LinkIcon } from "@icarius-icons/index";

const LinkInput = ({ data, handleChange }) => {

    const [linkDialogIsOpen, setLinkDialogIsOpen] = useState(false);

    return (
        <>
            <Tooltip title="Ingresar link">
                <LinkIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => setLinkDialogIsOpen(true)}
                />
            </Tooltip>
            {
                linkDialogIsOpen &&
                <LinkDialog
                    open={linkDialogIsOpen}
                    initialValue={data}
                    handleChange={(value) => handleChange(value)}
                    handleClose={() => setLinkDialogIsOpen(false)}
                />
            }
        </>
    )
}

export default LinkInput;