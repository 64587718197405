import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";

const PollDialog = (props) => {

    const {
        open,
        polls,
        initialValue,
        handleChange,
        handleClose,
    } = props;

    const [value, setValue] = useState(initialValue);

    const handleSubmit = () => {
        handleChange(value);
        handleClose();
    }

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={'sm'}
            fullWidth
            onClose={handleClose}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {'Asociar a encuesta'}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel shrink id={`label-poll`}>{"Encuesta"}</InputLabel>
                    <Select
                        value={value}
                        labelId={`label-poll`}
                        id={`select-poll`}
                        onChange={(e) => setValue(e.target.value)}
                        margin={"none"}
                        displayEmpty
                    >
                        <MenuItem
                            className={"whiteText"}
                            value={''}>
                            {'Ninguna'}
                        </MenuItem>
                        {
                            polls?.map(item => (
                                <MenuItem
                                    className={"whiteText"}
                                    key={item.key}
                                    value={item.key}>
                                    {item.value}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleSubmit} isAccept text={'Guardar'} />
            </DialogActions>
        </Dialog>
    )
}

export default PollDialog;