import { createDateFromDDMMYYYY, formatDateYYYYMMDD } from "@icarius-utils/date";
import { useState } from "react";

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, isFeedback, statusToCreate, getGoals, typeList, preselectedData) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'date') {
        return (isCreate || !data[fieldName]) ? null : createDateFromDDMMYYYY(data[fieldName]);
      }

      if (fieldName === 'link') {
        return (isCreate || !data[fieldName]) ? '' : data[fieldName];
      }

      if (fieldName === 'privacy') {
        if (isCreate) {
          if (isFeedback) {
            if (preselectedData?.userCodes) return { type: 'P', userCodes: preselectedData?.userCodes };
            return { type: 'P', userCodes: [] };
          }

          if (preselectedData?.userCodes) return { type: 'L', userCodes: preselectedData?.userCodes };
          return { type: 'U', userCodes: [] };
        }

        return data[fieldName];
      }

      if (fieldName === 'image') {
        return isCreate ? null : data[fieldName];
      }

      if (fieldName === 'file') {
        return isCreate ? null : data[fieldName];
      }

      if (fieldName === 'allowsComments') {
        return isCreate ? true : data[fieldName];
      }

      if (fieldName === 'mode') {
        if (preselectedData?.mode) return preselectedData?.mode;
        return isCreate ? 'E' : data[fieldName];
      }

      if (fieldName === 'status') {
        return isCreate ? statusToCreate : data[fieldName];
      }

      if (fieldName === 'pollCode') {
        return isCreate ? '' : (data?.poll?.key || '');
      }

      if (fieldName === 'reference') {
        if (preselectedData?.reference) return preselectedData?.reference;
        if (preselectedData?.goal) return `${preselectedData?.goal.name} - ${`Fecha de término: ${preselectedData?.goal.endDate}`} - ${`% de progreso: ${preselectedData?.goal.progress}%`}`;
        return isCreate ? '' : data[fieldName];
      }

      if (fieldName === 'type') {
        if (preselectedData?.type) return preselectedData?.type;
        if (preselectedData?.goal) return typeList.find((item) => item.key === 'TLM') ? 'TLM' : '';
        return isCreate ? '' : data[fieldName];
      }

      if (fieldName === 'goal') {
        if (preselectedData?.goal) return preselectedData?.goal.key;
        return isCreate ? '' : data[fieldName];
      }

      if (fieldName === 'evaluationProcess') {
        if (preselectedData?.evaluationProcess) return preselectedData?.evaluationProcess;
        return isCreate ? '' : data[fieldName];
      }

      if (fieldName === 'onboardingAction') {
        if (preselectedData?.onboardingAction) return preselectedData?.onboardingAction;
        return isCreate ? '' : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'text',
      'htmlText',
      'date',
      'image',
      'file',
      'link',
      'privacy',
      'allowsComments',
      'type',
      'mode',
      'reference',
      'status',
      'pollCode',
      'goal',
      'evaluationProcess',
      'onboardingAction',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = () => {
    if (isFeedback) {
      if (!formData.mode) return false;
      if (!formData.reference) return false;
      if (!formData.type) return false;
    }
    if (formData.privacy.type !== 'U' && !formData.privacy.userCodes.length) return false;
    return Boolean(formData.text);
  }

  const submit = () => {
    if (dataIsValid()) {
      let dataToSend = {
        ...formData,
      };

      if (!isCreate) {
        dataToSend.keepFile = Boolean(data.file && data.file === formData.file);
        dataToSend.file = data.file === formData.file ? null : formData.file;

        dataToSend.keepImage = Boolean(data.image && data.image === formData.image);
        dataToSend.image = data.image === formData.image ? null : formData.image;
      }

      if (formData.date) {
        dataToSend.date = formatDateYYYYMMDD(dataToSend.date);
      }

      isCreate ? createCallback(dataToSend) : modifyCallback({ publicationCode: data.publicationCode, ...dataToSend });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setReferenceTypeGoal = (newRef, newType, newGoal) => {
    setFormData({
      ...formData,
      reference: newRef,
      type: newType,
      goal: newGoal,
    });
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'text') {
      setFormData({
        ...formData,
        text: value.text,
        htmlText: value.htmlText,
      });
      return;
    }

    if (fieldName === 'privacy') {
      setFormData({
        ...formData,
        [fieldName]: value,
      });

      if (isFeedback && value.type === 'P') {
        getGoals(value.userCodes[0], formData.status);
      }

      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, setReferenceTypeGoal, submit };
}

export default useHandleForm;
