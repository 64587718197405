import React, { useState } from "react";
import { MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getUserData } from "src/app/selectors";
import Header from "../publication/components/header";
import DisabledIndicator from "../publication/components/disabledIndicator";
import Form from "./form";
import { useDispatch } from "react-redux";
import DisableDialog from "../publication/components/disableDialog";

const CommentItem = (props) => {

    const {
        data,
        people,
        privacyUserCodes,
        publicationCode,
        isLoading,
        deleteCommentAction,
        changeCommentStatusAction,
        createCommentAction,
        updateCommentAction,
        isFeedback,
        readOnly,
    } = props;

    const [formIsOpen, setFormIsOpen] = useState(false);
    const [disableDialogIsOpen, setDisableDialogIsOpen] = useState(false);

    const dispatch = useDispatch();
    const userData = useSelector(getUserData);

    const isOwner = userData.code === data.owner.code;
    const canDisable = userData.level === 'E' && !isOwner && data.status?.enabled === true;
    const canReactivate = userData.level === 'E' && !isOwner && data.status?.enabled === false;

    const handleDelete = () => {
        dispatch(deleteCommentAction({ internalCode: data.internalCode }));
    }

    const handleChangeStatus = (reason) => {
        dispatch(changeCommentStatusAction({
            reason,
            internalCode: data.internalCode,
            enabled: !data.status.enabled,
        }))
    }

    return (
        <>
            <div
                style={{
                    marginTop: 10,
                    padding: 20,
                    paddingTop: 10,
                    borderRadius: 10,
                    background: 'var(--mainBackgroundColor)',
                    border: '1px solid var(--medalTableBorder)',
                }}
            >
                <DisabledIndicator
                    data={data.status}
                />
                <Header
                    image={data.owner.image}
                    name={data.owner.name}
                    role={data.owner.role}
                    date={data.modifiedDate}
                    time={data.modifiedTime}
                    edited={data.edited}
                    hasMenu={!isLoading && !readOnly && (isOwner || canDisable || canReactivate)}
                    menuOptions={
                        [
                            canDisable && !isFeedback &&
                            <MenuItem key={1} onClick={() => setDisableDialogIsOpen(true)}>
                                Desactivar
                            </MenuItem>,
                            canReactivate && !isFeedback &&
                            <MenuItem key={2} onClick={() => handleChangeStatus('')}>
                                Reactivar
                            </MenuItem>,
                            isOwner && !formIsOpen &&
                            <MenuItem key={3} onClick={() => setFormIsOpen(true)}>Editar</MenuItem>,
                            isOwner &&
                            <MenuItem key={4} onClick={handleDelete}>Borrar</MenuItem>,
                        ]
                    }
                />
                <div style={{ padding: '10px 0px' }}>
                    <Form
                        readOnly={!formIsOpen}
                        data={data}
                        privacyUserCodes={privacyUserCodes}
                        publicationCode={publicationCode}
                        people={people}
                        createCommentAction={createCommentAction}
                        updateCommentAction={updateCommentAction}
                        handleClose={() => setFormIsOpen(false)}
                        isFeedback={isFeedback}
                    />
                </div>
            </div>
            {
                disableDialogIsOpen &&
                <DisableDialog
                    open={disableDialogIsOpen}
                    title="Desactivar comentario"
                    handleSubmit={handleChangeStatus}
                    handleClose={() => setDisableDialogIsOpen(false)}
                />
            }
        </>
    )
}

export default CommentItem;