import * as actionTypes from "./actionTypes";

const initialState = {
  isLoadingFeed: false,
  isLoadingInitialData: false,
  isLoadingComments: false,
  isLoadingLikesParticipation: false,
  data: [],
  polls: [],
  people: [],
  groups: [],
  tasks: [],
  comments: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_INITIAL:
      return { ...state, isLoadingInitialData: true };
    case actionTypes.GET_INITIAL_FULFILLED:
      return {
        ...state,
        isLoadingInitialData: false,
        polls: action.payload.polls,
        people: action.payload.people,
        groups: action.payload.groups,
        tasks: action.payload.tasks,
      };
    case actionTypes.GET_INITIAL_REJECTED:
      return { ...state, isLoadingInitialData: false };

    // feed //////////////////////////////////////////////////////////////////////
    case actionTypes.GET_FEED:
      return { ...state, isLoadingFeed: true };
    case actionTypes.GET_FEED_FULFILLED: {
      // filtrar por si viene alguna que ya tengo
      const publications = [...action.payload.data].filter((newPublication) => {
        return !state.data.some((originalPublication) => originalPublication.publicationCode === newPublication.publicationCode);
      });

      return {
        ...state,
        isLoadingFeed: false,
        data: action.payload.shouldResetData ? action.payload.data : [...state.data, ...publications],
      };
    }
    case actionTypes.GET_FEED_REJECTED:
      return { ...state, isLoadingFeed: false };

    case actionTypes.CREATE_FEED_PUBLICATION:
      return { ...state, isLoadingFeed: true };
    case actionTypes.CREATE_FEED_PUBLICATION_FULFILLED:
      return {
        ...state,
        isLoadingFeed: false,
        data: [action.payload.data, ...state.data],
      };
    case actionTypes.CREATE_FEED_PUBLICATION_REJECTED:
      return { ...state, isLoadingFeed: false, };

    case actionTypes.UPDATE_FEED_PUBLICATION:
      return { ...state, isLoadingFeed: true };
    case actionTypes.UPDATE_FEED_PUBLICATION_FULFILLED: {
      const publications = [...state.data];

      const indexToReplace = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexToReplace > -1) {
        publications[indexToReplace] = action.payload.data;
      }

      return {
        ...state,
        isLoadingFeed: false,
        data: publications,
      };
    }
    case actionTypes.UPDATE_FEED_PUBLICATION_REJECTED:
      return { ...state, isLoadingFeed: false, };

    case actionTypes.DELETE_FEED_PUBLICATION:
      return { ...state, isLoadingFeed: true };
    case actionTypes.DELETE_FEED_PUBLICATION_FULFILLED: {
      const publications = [...state.data].filter((item) => item.publicationCode !== action.payload.data.publicationCode);

      return {
        ...state,
        isLoadingFeed: false,
        data: publications,
      };
    }
    case actionTypes.DELETE_FEED_PUBLICATION_REJECTED:
      return { ...state, isLoadingFeed: false, };

    case actionTypes.CHANGE_FEED_PUBLICATION_STATUS:
      return { ...state, isLoadingFeed: true };
    case actionTypes.CHANGE_FEED_PUBLICATION_STATUS_FULFILLED: {
      const publications = [...state.data];

      const indexToReplace = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexToReplace > -1) {
        publications[indexToReplace] = action.payload.data;
      }

      return {
        ...state,
        isLoadingFeed: false,
        data: publications,
      };
    }
    case actionTypes.CHANGE_FEED_PUBLICATION_STATUS_REJECTED:
      return { ...state, isLoadingFeed: false, };


    // comments //////////////////////////////////////////////////////////////////////
    case actionTypes.GET_COMMENTS:
      return { ...state, isLoadingComments: true };
    case actionTypes.GET_COMMENTS_FULFILLED:
      return {
        ...state,
        isLoadingComments: false,
        comments: [...state.comments, action.payload.data],
      };
    case actionTypes.GET_COMMENTS_REJECTED:
      return { ...state, isLoadingComments: false };

    case actionTypes.CREATE_COMMENT:
      return { ...state, isLoadingComments: true };
    case actionTypes.CREATE_COMMENT_FULFILLED: {
      const auxComments = [...state.comments];
      const publications = [...state.data];

      const indexCommentsToReplace = auxComments.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexCommentsToReplace > -1) {
        auxComments[indexCommentsToReplace] = action.payload.data;
      }

      const indexPublicationsToReplace = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexPublicationsToReplace > -1) {
        publications[indexPublicationsToReplace] = {
          ...publications[indexPublicationsToReplace],
          commentsAmount: action.payload.data.comments.length,
        }
      }

      return {
        ...state,
        isLoadingComments: false,
        comments: auxComments,
        data: publications,
      };
    }
    case actionTypes.CREATE_COMMENT_REJECTED:
      return { ...state, isLoadingComments: false };

    case actionTypes.UPDATE_COMMENT:
      return { ...state, isLoadingComments: true };
    case actionTypes.UPDATE_COMMENT_FULFILLED: {
      const auxComments = [...state.comments];
      const publications = [...state.data];

      const indexCommentsToReplace = auxComments.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexCommentsToReplace > -1) {
        auxComments[indexCommentsToReplace] = action.payload.data;
      }

      const indexPublicationsToReplace = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexPublicationsToReplace > -1) {
        publications[indexPublicationsToReplace] = {
          ...publications[indexPublicationsToReplace],
          commentsAmount: action.payload.data.comments.length,
        }
      }

      return {
        ...state,
        isLoadingComments: false,
        comments: auxComments,
        data: publications,
      };
    }
    case actionTypes.UPDATE_COMMENT_REJECTED:
      return { ...state, isLoadingComments: false };

    case actionTypes.DELETE_COMMENT:
      return { ...state, isLoadingComments: true };
    case actionTypes.DELETE_COMMENT_FULFILLED: {
      const auxComments = [...state.comments];
      const publications = [...state.data];

      const indexCommentsToReplace = auxComments.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexCommentsToReplace > -1) {
        auxComments[indexCommentsToReplace] = action.payload.data;
      }

      const indexPublicationsToReplace = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexPublicationsToReplace > -1) {
        publications[indexPublicationsToReplace] = {
          ...publications[indexPublicationsToReplace],
          commentsAmount: action.payload.data.comments.length,
        }
      }

      return {
        ...state,
        isLoadingComments: false,
        comments: auxComments,
        data: publications,
      };
    }
    case actionTypes.DELETE_COMMENT_REJECTED:
      return { ...state, isLoadingComments: false, };

    case actionTypes.CHANGE_COMMENT_STATUS:
      return { ...state, isLoadingComments: true };
    case actionTypes.CHANGE_COMMENT_STATUS_FULFILLED: {
      const auxComments = [...state.comments];
      const publications = [...state.data];

      const indexCommentsToReplace = auxComments.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexCommentsToReplace > -1) {
        auxComments[indexCommentsToReplace] = action.payload.data;
      }

      const indexPublicationsToReplace = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexPublicationsToReplace > -1) {
        publications[indexPublicationsToReplace] = {
          ...publications[indexPublicationsToReplace],
          commentsAmount: action.payload.data.comments.length,
        }
      }

      return {
        ...state,
        isLoadingComments: false,
        comments: auxComments,
        data: publications,
      };
    }
    case actionTypes.CHANGE_COMMENT_STATUS_REJECTED:
      return { ...state, isLoadingComments: false };

    // likes //////////////////////////////////////////////////////////////////////
    case actionTypes.LIKE:
      return { ...state, isLoadingLikesParticipation: true };
    case actionTypes.LIKE_FULFILLED: {
      const publications = [...state.data];

      const indexToEdit = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);

      if (indexToEdit > -1) {
        let auxLikes;

        if (action.payload.data.status) {
          auxLikes = [...publications[indexToEdit].likes, action.payload.data.userCode];
        } else {
          auxLikes = publications[indexToEdit].likes.filter((item) => item !== action.payload.data.userCode);
        }

        publications[indexToEdit].likes = auxLikes;
      }

      return {
        ...state,
        isLoadingLikesParticipation: false,
        data: publications,
      };
    }
    case actionTypes.LIKE_REJECTED:
      return { ...state, isLoadingLikesParticipation: false };

    // participation //////////////////////////////////////////////////////////////////////
    case actionTypes.INDICATE_PARTICIPATION:
      return { ...state, isLoadingLikesParticipation: true };
    case actionTypes.INDICATE_PARTICIPATION_FULFILLED: {
      const publications = [...state.data];

      const indexToEdit = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);

      if (indexToEdit > -1) {
        let auxParticipation;

        if (action.payload.data.status) {
          auxParticipation = [...publications[indexToEdit].participation, action.payload.data.userCode];
        } else {
          auxParticipation = publications[indexToEdit].participation.filter((item) => item !== action.payload.data.userCode);
        }

        publications[indexToEdit].participation = auxParticipation;
      }

      return {
        ...state,
        isLoadingLikesParticipation: false,
        data: publications,
      };
    }
    case actionTypes.INDICATE_PARTICIPATION_REJECTED:
      return { ...state, isLoadingLikesParticipation: false };

    case actionTypes.DOWNLOAD_PARTICIPATION:
      return { ...state, isLoadingLikesParticipation: true };
    case actionTypes.DOWNLOAD_PARTICIPATION_FULFILLED:
      return { ...state, isLoadingLikesParticipation: false };
    case actionTypes.DOWNLOAD_PARTICIPATION_REJECTED:
      return { ...state, isLoadingLikesParticipation: false };

    case actionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
