import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import GoalDialog from "./goalDialog";

const GoalInput = (props) => {

    const {
        goals,
        handleChange,
    } = props;

    const dispatch = useDispatch();
    const [goalDialogIsOpen, setGoalDialogIsOpen] = useState(false);

    const handleOpenDialog = () => {
        if (!Boolean(goals.length)) {
            dispatch(openSnackbarAction({
                msg: 'El usuario seleccionado no posee metas y objetivos activos',
                severity: "info",
            }));
            return;
        }
        setGoalDialogIsOpen(true);
    }

    return (
        <>
            <Tooltip title="Metas y objetivos">
                <PlaylistAddCheckIcon
                    style={{ cursor: 'pointer' }}
                    onClick={handleOpenDialog}
                />
            </Tooltip>
            {
                goalDialogIsOpen &&
                <GoalDialog
                    open={goalDialogIsOpen}
                    goals={goals}
                    handleChange={(newRef, newGoal) => handleChange(newRef, newGoal)}
                    handleClose={() => setGoalDialogIsOpen(false)}
                />
            }
        </>
    )
}

export default GoalInput;