import React from "react";
import { CloseIcon } from "@icarius-icons/index";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";

const Image = (props) => {

    const {
        data,
        readOnly,
        handleDelete,
    } = props;

    const getImageUrl = () => {
        if (!data) return null;
        if (data?.path) return `${RESOURCES_ENDPOINT}${data.path}`;
        return URL.createObjectURL(data)
    };
    
    if (!data) return null;

    return (
        <div style={{ margin: '10px 0px', display: 'flex', justifyContent: 'center' }}>
            <img
                style={{ maxHeight: 600, maxWidth: '85%' }}
                src={getImageUrl()}
                alt=""
            />
            {
                !readOnly &&
                <CloseIcon
                    onClick={handleDelete}
                    style={{ cursor: 'pointer', marginLeft: 10 }}
                />
            }
        </div>
    )
}

export default Image;