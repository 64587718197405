import React from "react";
import { useSelector } from "react-redux";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { getAppColor } from "src/app/selectors";
import { createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { formatDraftToText } from "@icarius-utils/format";
import CommonPage from "@icarius-common/commonPage";
import ProfileImg from "@icarius-common/profileImg";
import 'react-vertical-timeline-component/style.min.css';

const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

const Timeline = (props) => {

    const {
        typeList,
        people,
        data,
        groups,
        tasks,
        comments,
        handleGoBack,
    } = props;

    const color = useSelector(getAppColor);

    const goBackButton = () => (
        <CustomIconButton
            title={'Volver'}
            onClick={handleGoBack}
            type={"goBack"}
        />
    )

    const publicationComments = (comments.find((item) => item.publicationCode === data[0].publicationCode)?.comments || []).reverse();

    return (
        <CommonPage
            isNotGridPage
            title={'Feedback | Línea de tiempo'}
            menuItems={[goBackButton]}

        >
            <VerticalTimeline lineColor={color} layout={'2-columns'}>
                {
                    data.map((item, index) => {
                        const date = createDateFromDDMMYYYY(item.modifiedDate);
                        const month = date.getMonth();

                        const getReceiverName = () => {
                            if (item.privacy.type === 'P') {
                                const privateReceiver = item.privacy.userCodes.filter((code) => code !== item.owner.code)[0];
                                return people.find((person) => person.key === privateReceiver)?.name;
                            }

                            if (item.privacy.type === 'L') {
                                return 'Varios colaboradores seleccionadis';
                            }

                            if (item.privacy.type === 'G') {
                                return `Grupo ${groups.find((group) => group.key === item.group)?.name}`;
                            }

                            if (item.privacy.type === 'T') {
                                return `Equipo de la tarea ${tasks.find((task) => task.key === item.task)?.name}`;
                            }
                            
                            return '';
                        }

                        return (
                            <VerticalTimelineElement
                                key={index}
                                position={'left'}
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: 'var(--medalTableHeader)', color: 'var(--mainText)', boxShadow: 'none' }}
                                contentArrowStyle={{ borderRight: '7px solid  var(--medalTableHeader)' }}
                                iconStyle={{ background: color }}
                                date={`${monthNames[month]} - ${formatDate(date)}`}
                            >
                                <div style={{ marginBottom: 10 }}>
                                    <p style={{ margin: 0, fontWeight: 300 }}>
                                        {`De: ${item.owner.name}`}
                                    </p>
                                    <p style={{ margin: 0, fontWeight: 300 }}>
                                        {`A: ${getReceiverName()}`}
                                    </p>
                                </div>
                                <h3 className="vertical-timeline-element-title">
                                    {item.statusText}
                                </h3>
                                <h3 className="vertical-timeline-element-title" style={{ color: color }}>
                                    {item.reference}
                                </h3>
                                <p style={{ margin: 0, fontWeight: 300, whiteSpace: 'pre-line' }}>
                                    {formatDraftToText(item.text)}
                                </p>
                                <p style={{ margin: 0, fontWeight: 500, fontStyle: 'italic', marginTop: 10 }}>
                                    {typeList.find((typeItem) => typeItem.key === item.type)?.value || ''}
                                </p>
                            </VerticalTimelineElement>
                        )
                    })
                }
                {
                    publicationComments.map((item, index) => {
                        const date = createDateFromDDMMYYYY(item.modifiedDate);
                        const month = date.getMonth();

                        const person = people.find((personItem) => personItem.key === item.owner.code);

                        return (
                            <VerticalTimelineElement
                                key={index}
                                position={'right'}
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: 'var(--medalTableHeader)', color: 'var(--mainText)', boxShadow: 'none' }}
                                contentArrowStyle={{ borderRight: '7px solid  var(--medalTableHeader)' }}
                                iconStyle={{ background: color }}
                                date={`${monthNames[month]} - ${formatDate(date)}`}
                            >
                                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                    <ProfileImg
                                        size={30}
                                        image={person.img}
                                    />
                                    <h3 className="vertical-timeline-element-title" style={{ color }}>
                                        {person.name}
                                    </h3>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ margin: 0, fontWeight: 600 }}>
                                        {`${person.position}, ${person.workplace}`}
                                    </p>
                                </div>
                                <p style={{ margin: 0, fontWeight: 300, whiteSpace: 'pre-line' }}>
                                    {formatDraftToText(item.text)}
                                </p>
                            </VerticalTimelineElement>
                        )
                    })
                }
            </VerticalTimeline>
        </CommonPage>
    );
}

export default Timeline;