import * as actionTypes from "./actionTypes";

const initialState = {
  isLoadingGridData: false,
  isLoadingFeed: false,
  isLoadingPrivacyData: false,
  isLoadingComments: false,
  isLoadingLikesParticipation: false,
  gridData: [],
  modeList: [],
  typeList: [],
  people: [],
  groups: [],
  tasks: [],
  feedData: [],
  comments: [],
  goals: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoadingGridData: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoadingGridData: false,
        gridData: action.payload.data,
        typeList: action.payload.typeList,
        modeList: action.payload.modeList,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoadingGridData: false };

    // privacy //////////////////////////////////////////////////////////////////////
    case actionTypes.GET_PEOPLE:
      return { ...state, isLoadingPrivacyData: true };
    case actionTypes.GET_PEOPLE_FULFILLED:
      return {
        ...state,
        isLoadingPrivacyData: false,
        people: action.payload.people,
        groups: action.payload.groups,
        tasks: action.payload.tasks,
      };
    case actionTypes.GET_PEOPLE_REJECTED:
      return { ...state, isLoadingPrivacyData: false };
    // goals //////////////////////////////////////////////////////////////////////
    case actionTypes.GET_GOALS:
      return { ...state, isLoadingFeed: true };
    case actionTypes.GET_GOALS_FULFILLED:
      return {
        ...state,
        isLoadingFeed: false,
        goals: action.payload.goals,
      };
    case actionTypes.GET_GOALS_REJECTED:
      return { ...state, isLoadingFeed: false };

    // feed //////////////////////////////////////////////////////////////////////
    case actionTypes.GET_FEED:
      return { ...state, isLoadingFeed: true };
    case actionTypes.GET_FEED_FULFILLED: {
      // filtrar por si viene alguna que ya tengo
      const publications = [...action.payload.data].filter((newPublication) => {
        return !state.feedData.some((originalPublication) => originalPublication.publicationCode === newPublication.publicationCode);
      });

      return {
        ...state,
        isLoadingFeed: false,
        feedData: [...state.feedData, ...publications],
      };
    }
    case actionTypes.GET_FEED_REJECTED:
      return { ...state, isLoadingFeed: false };

    case actionTypes.CREATE_FEED_PUBLICATION:
      return { ...state, isLoadingFeed: true };
    case actionTypes.CREATE_FEED_PUBLICATION_FULFILLED:
      return {
        ...state,
        isLoadingFeed: false,
        feedData: [action.payload.data, ...state.feedData],
        people: action.payload.people,
        groups: action.payload.groups,
        tasks: action.payload.tasks,
      };
    case actionTypes.CREATE_FEED_PUBLICATION_REJECTED:
      return { ...state, isLoadingFeed: false, };

    case actionTypes.UPDATE_FEED_PUBLICATION:
      return { ...state, isLoadingFeed: true };
    case actionTypes.UPDATE_FEED_PUBLICATION_FULFILLED: {
      const publications = [...state.feedData];

      const indexToReplace = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexToReplace > -1) {
        publications[indexToReplace] = action.payload.data;
      }

      return {
        ...state,
        isLoadingFeed: false,
        feedData: publications,
      };
    }
    case actionTypes.UPDATE_FEED_PUBLICATION_REJECTED:
      return { ...state, isLoadingFeed: false, };

    case actionTypes.DELETE_FEED_PUBLICATION:
      return { ...state, isLoadingFeed: true };
    case actionTypes.DELETE_FEED_PUBLICATION_FULFILLED: {
      const newFeedData = [...state.feedData].filter((item) => item.publicationCode !== action.payload.data.publicationCode);
      const newGridData = [...state.gridData].filter((item) => item.publicationCode !== action.payload.data.publicationCode);

      return {
        ...state,
        isLoadingFeed: false,
        feedData: newFeedData,
        gridData: newGridData,
      };
    }
    case actionTypes.DELETE_FEED_PUBLICATION_REJECTED:
      return { ...state, isLoadingFeed: false, };

    // comments //////////////////////////////////////////////////////////////////////
    case actionTypes.GET_COMMENTS:
      return { ...state, isLoadingComments: true };
    case actionTypes.GET_COMMENTS_FULFILLED:
      return {
        ...state,
        isLoadingComments: false,
        comments: [...state.comments, action.payload.data],
      };
    case actionTypes.GET_COMMENTS_REJECTED:
      return { ...state, isLoadingComments: false };

    case actionTypes.CREATE_COMMENT:
      return { ...state, isLoadingComments: true };
    case actionTypes.CREATE_COMMENT_FULFILLED: {
      const auxComments = [...state.comments];
      const publications = [...state.feedData];

      const indexCommentsToReplace = auxComments.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexCommentsToReplace > -1) {
        auxComments[indexCommentsToReplace] = action.payload.data;
      }

      const indexPublicationsToReplace = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexPublicationsToReplace > -1) {
        publications[indexPublicationsToReplace] = {
          ...publications[indexPublicationsToReplace],
          commentsAmount: action.payload.data.comments.length,
          statusText: action.payload.data.statusText,
        }
      }

      return {
        ...state,
        isLoadingComments: false,
        comments: auxComments,
        feedData: publications,
      };
    }
    case actionTypes.CREATE_COMMENT_REJECTED:
      return { ...state, isLoadingComments: false };

    case actionTypes.UPDATE_COMMENT:
      return { ...state, isLoadingComments: true };
    case actionTypes.UPDATE_COMMENT_FULFILLED: {
      const auxComments = [...state.comments];
      const publications = [...state.feedData];

      const indexCommentsToReplace = auxComments.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexCommentsToReplace > -1) {
        auxComments[indexCommentsToReplace] = action.payload.data;
      }

      const indexPublicationsToReplace = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexPublicationsToReplace > -1) {
        publications[indexPublicationsToReplace] = {
          ...publications[indexPublicationsToReplace],
          commentsAmount: action.payload.data.comments.length,
          statusText: action.payload.data.statusText,
        }
      }

      return {
        ...state,
        isLoadingComments: false,
        comments: auxComments,
        feedData: publications,
      };
    }
    case actionTypes.UPDATE_COMMENT_REJECTED:
      return { ...state, isLoadingComments: false };

    case actionTypes.DELETE_COMMENT:
      return { ...state, isLoadingComments: true };
    case actionTypes.DELETE_COMMENT_FULFILLED: {
      const auxComments = [...state.comments];
      const publications = [...state.feedData];

      const indexCommentsToReplace = auxComments.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexCommentsToReplace > -1) {
        auxComments[indexCommentsToReplace] = action.payload.data;
      }

      const indexPublicationsToReplace = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);
      if (indexPublicationsToReplace > -1) {
        publications[indexPublicationsToReplace] = {
          ...publications[indexPublicationsToReplace],
          commentsAmount: action.payload.data.comments.length,
          statusText: action.payload.data.statusText,
        }
      }

      return {
        ...state,
        isLoadingComments: false,
        comments: auxComments,
        feedData: publications,
      };
    }
    case actionTypes.DELETE_COMMENT_REJECTED:
      return { ...state, isLoadingComments: false, };

    // likes //////////////////////////////////////////////////////////////////////
    case actionTypes.LIKE:
      return { ...state, isLoadingLikesParticipation: true };
    case actionTypes.LIKE_FULFILLED: {
      const publications = [...state.feedData];

      const indexToEdit = publications.findIndex((item) => item.publicationCode === action.payload.data.publicationCode);

      if (indexToEdit > -1) {
        let auxLikes;

        if (action.payload.data.status) {
          auxLikes = [...publications[indexToEdit].likes, action.payload.data.userCode];
        } else {
          auxLikes = publications[indexToEdit].likes.filter((item) => item !== action.payload.data.userCode);
        }

        publications[indexToEdit].likes = auxLikes;
      }

      return {
        ...state,
        isLoadingLikesParticipation: false,
        feedData: publications,
      };
    }
    case actionTypes.LIKE_REJECTED:
      return { ...state, isLoadingLikesParticipation: false };

    case actionTypes.RESET_FEED_AND_COMMENTS:
      return {
        ...initialState,
        gridData: state.gridData,
        modeList: state.modeList,
        typeList: state.typeList,
        people: state.people,
        groups: state.groups,
        tasks: state.tasks,
      };

    case actionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
