import React from "react";
import { Typography } from "@material-ui/core";
import { CloseIcon } from "@icarius-icons/index";
import { formatDate } from "@icarius-utils/date";

const EventDate = (props) => {

    const {
        data,
        readOnly,
        isFeedback,
        handleDelete,
    } = props;

    if(!data) return null;

    return (
        <div style={{ padding: '10px 0px', display: 'flex' }}>
            <Typography className="whiteText">
                {`Fecha ${isFeedback ? 'de revisión' : 'del evento'}:`} <b>{formatDate(data)}</b>
            </Typography>
            {
                !readOnly &&
                <CloseIcon
                    style={{ cursor: 'pointer', marginLeft: 10 }}
                    onClick={handleDelete}
                />
            }
        </div>
    )
}

export default EventDate;