import React, { useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    TextField,
    InputAdornment,
    Button,
    Icon,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import ProfileImg from "@icarius-common/profileImg";
import { ExcelIcon, SearchIcon } from "@icarius-icons/index";
import Loader from "@icarius-common/loader";
import DialogTransition from "@icarius-common/dialogTransition";

const PeopleDialog = (props) => {

    const {
        isLoading,
        open,
        data,
        title,
        people,
        handleDownload,
        handleClose,
    } = props;

    const [searchValue, setSearchValue] = useState('');
    const filteredPeople = people.filter((item) => data.includes(item.key) && item.name.toLowerCase().includes(searchValue.toLocaleLowerCase()));

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={'sm'}
            fullWidth
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {title}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent style={{ paddingTop: 0 }}>
                <Loader open={isLoading} />
                <TextField
                    fullWidth
                    margin={"none"}
                    label={'Buscar por nombre'}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <div style={{ height: 320, overflow: 'auto' }}>
                    {
                        !filteredPeople.length &&
                        <Typography className="whiteText" align="center" style={{ fontSize: 24, margin: 20 }}>
                            No se encontraron resultados
                        </Typography>
                    }
                    {
                        filteredPeople.map((item) => {
                            return (
                                <div
                                    key={item.key}
                                    style={{
                                        display: 'flex',
                                        margin: '20px 0px',
                                    }}
                                >
                                    <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 20 }}>
                                            <ProfileImg
                                                image={item.img}
                                                size={40}
                                            />
                                        </div>
                                        <Typography className="whiteText" style={{ fontSize: 20 }}>
                                            {item.name}
                                        </Typography>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    handleDownload &&
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0px' }}>
                        <Button
                            onClick={handleDownload}
                            variant="contained"
                            className="whiteText"
                            startIcon={
                                <Icon style={{ display: 'flex', fill: 'var(--mainText)', height: 25 }}>
                                    <ExcelIcon />
                                </Icon>
                            }
                        >
                            Exportar
                        </Button>
                    </div>
                }
            </DialogContent>
        </Dialog>
    )
}

export default PeopleDialog;