import React from "react";
import { Typography } from "@material-ui/core";

const ReferenceIndicator = ({ data }) => {

    if (!data) return null;

    return (
        <Typography className="whiteText" style={{ paddingTop: 10 }}>
            {data}
        </Typography>
    )
}

export default ReferenceIndicator;