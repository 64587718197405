const NAME = "FEED";

export const PRIVACY_NAMES = {
    'P': 'Privado',
    'U': 'Público',
    'L': 'Personalizado',
    'G': 'Grupo',
    'T': 'Equipo de tarea',
}

export default NAME;
